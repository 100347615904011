@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");
@import '~@ng-select/ng-select/themes/default.theme.css';
@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope-ExtraBold.woff2") format("woff2"), url("../assets/fonts/Manrope-ExtraBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope-Bold.woff2") format("woff2"), url("../assets/fonts/Manrope-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope-SemiBold.woff2") format("woff2"), url("../assets/fonts/Manrope-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope-Medium.woff2") format("woff2"), url("../assets/fonts/Manrope-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope-Regular.woff2") format("woff2"), url("../assets/fonts/Manrope-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope-Light.woff2") format("woff2"), url("../assets/fonts/Manrope-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope-ExtraLight.woff2") format("woff2"), url("../assets/fonts/Manrope-ExtraLight.ttf") format("truetype");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../assets/fonts/BebasNeue-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
* {
  font-family: "Manrope", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.text-danger {
  color: #f23918 !important;
}

html {
  height: 100%;
  font-size: 14px;
  scroll-behavior: smooth;
}

iframe {
  border: 0;
}

.ng-select-sh {
  display: flex !important;
  margin: 0;
}
.ng-select-sh .ng-select-container .ng-value-container {
  overflow: initial !important;
}
.ng-select-sh .ng-select-container .ng-value-container .ng-placeholder {
  position: relative !important;
  top: auto !important;
  padding: 0 !important;
}
.ng-select-sh .ng-select-container .ng-value-container .ng-value {
  overflow: initial !important;
}
.ng-select-sh .ng-select-container .ng-value-container .ng-value ui-request-icons {
  width: 16px;
  height: 16px;
  display: block;
  margin-right: 12px;
}
.ng-select-sh .ng-select-container .ng-value-container .ng-value ui-request-icons svg {
  width: 16px;
  height: 16px;
  display: block;
}
.ng-select-sh .ng-select-container .ng-value-container .ng-value ui-flag-icon {
  margin-right: auto;
}
.ng-select-sh .ng-select-container .ng-value-container .ng-value ui-flag-icon svg {
  margin-right: 12px;
}
.ng-select-sh .ng-select-container .ng-value-container .ng-value .ng-value-label.type::before {
  vertical-align: 1px;
}
.ng-select-sh .ng-select-container svg path {
  transition: fill 0.3s ease;
}
.ng-select-sh .ng-select-container sh-avatar-new {
  margin: 0 12px 0 -6px;
}
.ng-select-sh .ng-select-container .ng-arrow-wrapper {
  display: none;
}
.ng-select-sh.ng-select-opened .ng-select-container .arrow .arrow-icon {
  transform: rotate(180deg);
}
.ng-select-sh.ng-select-disabled .ng-select-container {
  cursor: default !important;
}
.ng-select-sh .ng-dropdown-header {
  padding: 4px 4px 0;
  border: none;
  position: relative;
  z-index: 9999;
  background: #ffffff;
}
.ng-select-sh .ng-dropdown-header .search-input {
  border-radius: 6px;
  box-shadow: 0 1px 0 0 #f8f8f8;
  border: solid 1px #ededed;
  background-color: #ffffff;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 38px;
  letter-spacing: -0.2px;
  color: #050505;
  width: 100%;
  padding: 0 12px;
  height: 38px;
  appearance: none;
  outline: none;
}
.ng-select-sh .ng-dropdown-header .search-input:focus {
  outline: none;
}
.ng-select-sh .ng-dropdown-header .search-input::-webkit-input-placeholder {
  color: #9e9e9e;
}
.ng-select-sh .ng-dropdown-header .search-input:-moz-placeholder {
  color: #9e9e9e;
}
.ng-select-sh .ng-dropdown-header .search-input::-moz-placeholder {
  color: #9e9e9e;
}
.ng-select-sh .ng-dropdown-header .search-input:-ms-input-placeholder {
  color: #9e9e9e;
}
.ng-select-sh .ng-dropdown-panel {
  top: 100%;
  bottom: auto;
  padding: 0;
  margin: 4px 0 0 0;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  width: auto;
  animation: slide-up 0.4s ease;
  overflow: initial !important;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 185px;
  overflow-x: auto;
}
.ng-select-sh .ng-dropdown-panel.ng-select-top {
  top: auto;
  margin: 0 0 8px 0;
  bottom: 100%;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  font-family: "Manrope", sans-serif;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: normal;
  color: #767676;
  padding: 0 12px;
  margin: 12px 0 4px;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  margin: 0 4px 2px;
  padding: 8px 12px;
  border-radius: 8px !important;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  min-height: 40px;
  height: auto;
  line-height: 24px;
  position: relative;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:last-child:not(:first-child) {
  margin-bottom: 4px;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .clear {
  display: none;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option sh-avatar-new {
  margin: 0 8px 0 -4px;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option svg-icon.option-icon {
  margin-right: 12px;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option svg-icon.option-icon svg {
  width: 16px;
  height: 16px;
  display: block;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option ui-request-icons {
  width: 16px;
  height: 16px;
  display: block;
  margin-right: 12px;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option ui-request-icons svg {
  width: 16px;
  height: 16px;
  display: block;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-icon {
  display: block;
  margin-right: 12px;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-icon svg {
  display: block;
  width: 12px;
  height: 12px;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #050505;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label.type::before {
  vertical-align: 2px;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label.country {
  display: flex;
  width: 100%;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label.country .prefix {
  margin-left: auto;
  color: #9e9e9e !important;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label.country span:not(.prefix) {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 270px;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label.country ui-flag-icon {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label.country ui-flag-icon span {
  width: 22px;
  height: 16px;
  line-height: 16px;
  background-size: cover;
  background-position: center center;
  border-radius: 3px;
  margin-right: 12px;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label.country ui-flag-icon + span:first-letter {
  text-transform: uppercase;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-checkmark {
  display: none;
  margin-left: auto;
  will-change: transform;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-checkmark svg {
  display: block;
  width: 12px;
  height: 12px;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:first-child {
  margin-top: 4px;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  cursor: default;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:not(.ng-option-disabled):not(.ng-option-selected):hover, .ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #f2f2f2 !important;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: #f2f2f2 !important;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-checkmark, .ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-checkmark {
  display: block;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .clear, .ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .clear {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label, .ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  margin-right: 16px;
  font-weight: 600;
}
.ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label:not(.type) span, .ng-select-sh .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label:not(.type) span {
  color: #050505;
  font-weight: 600;
}
.ng-select-sh .ng-dropdown-footer {
  border: none;
  padding: 0;
  margin-top: -2px;
}
.ng-select-sh .ng-dropdown-footer .ng-option {
  margin: 0 4px 2px;
  padding: 8px 12px;
  border-radius: 8px !important;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 25px;
  cursor: pointer;
}
.ng-select-sh .ng-dropdown-footer .ng-option .ng-option-icon {
  display: block;
  margin-right: 12px;
}
.ng-select-sh .ng-dropdown-footer .ng-option .ng-option-icon svg {
  display: block;
  width: 24px;
  height: 18px;
}
.ng-select-sh .ng-dropdown-footer .ng-option .ng-option-label {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 25px;
  letter-spacing: -0.2px;
  color: #050505;
}
.ng-select-sh .ng-dropdown-footer .ng-option:last-child {
  margin-bottom: 4px;
}
.ng-select-sh .ng-dropdown-footer .ng-option:hover {
  background-color: #f2f2f2 !important;
}

.ng-select-100 .ng-dropdown-panel {
  width: 100%;
}

.ng-select-text-nowrap .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: inherit;
}

.ng-select-ellipsis .ng-select-container .ng-value-container {
  overflow: auto !important;
}
.ng-select-ellipsis .ng-select-container .ng-value-container .ng-value .ng-value-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ng-select-ellipsis .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ng-select-only-icon {
  position: initial !important;
}
.ng-select-only-icon .ng-select-container .ng-value-container .ng-value .option-icon {
  margin-right: 0 !important;
}
.ng-select-only-icon .ng-select-container .ng-value-container .ng-value .option-icon svg {
  width: 24px !important;
  height: 24px !important;
  display: block;
}
.ng-select-only-icon .ng-select-container .ng-value-container .ng-value .option-icon + span {
  display: none !important;
}
.ng-select-only-icon .ng-select-container .ng-value-container .ng-arrow-wrapper {
  margin-left: 8px !important;
}

.ng-select-new .ng-select-container {
  display: inline-flex !important;
  width: auto !important;
  min-height: 32px;
  max-height: 32px;
  height: 32px !important;
  border: none;
  border-radius: 6px !important;
  background-color: #ffffff;
  box-shadow: none !important;
  cursor: pointer !important;
  transition: all 0.3s ease;
}
.ng-select-new .ng-select-container:hover {
  background-color: #fafafa;
}
.ng-select-new .ng-select-container .ng-value-container {
  padding-left: 12px;
  padding-right: 12px;
}
.ng-select-new .ng-select-container .ng-value-container .ng-input {
  display: none !important;
}
.ng-select-new .ng-select-container .ng-value-container .ng-placeholder {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #9e9e9e;
}
.ng-select-new .ng-select-container .ng-value-container .ng-value {
  padding: 0;
  display: flex;
  align-items: center;
}
.ng-select-new .ng-select-container .ng-value-container .ng-value .ng-value-label {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #050505;
}
.ng-select-new .ng-select-container .ng-value-container .ng-value .select-icon svg {
  width: 16px;
  height: 16px;
  display: block;
}
.ng-select-new .ng-select-container .ng-value-container .ng-value .arrow {
  display: block;
  margin-left: 12px;
}
.ng-select-new .ng-select-container .ng-value-container .ng-value .arrow .arrow-icon svg {
  width: 12px;
  height: 12px;
  display: block;
}
.ng-select-new.ng-select-opened .ng-select-container {
  background-color: #f8f8f8 !important;
}

.ng-select-assign.ng-select-multiple {
  display: flex;
}
.ng-select-assign.ng-select-multiple .ng-select-container {
  display: inline-flex !important;
  width: auto !important;
  min-height: 32px;
  max-height: 32px;
  height: 32px !important;
  border: none;
  border-radius: 6px !important;
  background-color: #f2f2f2;
  box-shadow: none !important;
  cursor: pointer !important;
  transition: all 0.3s ease;
}
.ng-select-assign.ng-select-multiple .ng-select-container:hover {
  background-color: #ededed;
}
.ng-select-assign.ng-select-multiple .ng-select-container sh-avatar-new {
  margin-right: 8px;
  margin-left: -8px;
}
.ng-select-assign.ng-select-multiple .ng-select-container .ng-value-container {
  padding: 0 12px !important;
}
.ng-select-assign.ng-select-multiple .ng-select-container .ng-value-container .ng-value-label {
  padding: 0 !important;
}
.ng-select-assign.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  display: none !important;
}
.ng-select-assign.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #050505;
}
.ng-select-assign.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-size: inherit;
  margin-bottom: 0;
  background-color: transparent;
  border-radius: 0;
  margin-right: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.ng-select-assign.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #050505;
}
.ng-select-assign.ng-select-multiple .ng-select-container .ng-arrow-wrapper,
.ng-select-assign.ng-select-multiple .ng-select-container .ng-clear-wrapper {
  width: 12px;
  height: 12px;
  padding: 0;
  display: block;
  margin-right: 8px;
  margin-left: 1px;
  top: auto !important;
  transition: all 0.3s ease;
  transform-origin: center;
  will-change: transform;
  background-color: #050505;
  background-size: cover;
}
.ng-select-assign.ng-select-multiple .ng-select-container .ng-arrow-wrapper {
  -webkit-mask-image: url("/assets/icons/icons-arrow-down.svg");
  mask-image: url("/assets/icons/icons-arrow-down.svg");
  mask-size: 12px 12px;
}
.ng-select-assign.ng-select-multiple .ng-select-container .ng-arrow-wrapper .ng-arrow {
  display: none;
}
.ng-select-assign.ng-select-multiple .ng-select-container .ng-clear-wrapper {
  -webkit-mask-image: url("/assets/icons/icons-close-small.svg");
  mask-image: url("/assets/icons/icons-close-small.svg");
  mask-size: 12px 12px;
}
.ng-select-assign.ng-select-multiple .ng-select-container .ng-clear-wrapper:hover {
  background-color: #f23918;
}
.ng-select-assign.ng-select-multiple .ng-select-container .ng-clear-wrapper .ng-clear {
  display: none;
}
.ng-select-assign.ng-select-multiple .ng-select-container.ng-has-value .ng-arrow-wrapper {
  display: none;
}
.ng-select-assign.ng-select-multiple.ng-select-opened .ng-select-container {
  background-color: #e5e5e5 !important;
}
.ng-select-assign.ng-select-multiple.ng-select-opened .ng-select-container .ng-arrow-wrapper .ng-arrow {
  transform: rotate(180deg);
}
.ng-select-assign.ng-select-multiple.is-multiple-selected .ng-select-container .ng-arrow-wrapper,
.ng-select-assign.ng-select-multiple.is-multiple-selected .ng-select-container .ng-clear-wrapper {
  display: none;
}
.ng-select-assign.ng-select-multiple.is-multiple-selected .ng-select-container sh-avatar-new {
  margin: 0 0 0 -6px;
  border-radius: 6px;
  border-left: 2px solid #f2f2f2;
  border-top: 2px solid #f2f2f2;
  border-bottom: 2px solid #f2f2f2;
}
.ng-select-assign.ng-select-multiple.is-multiple-selected .ng-select-container .ng-value-container {
  padding: 0 4px 0 8px !important;
}
.ng-select-assign.ng-select-multiple.is-multiple-selected .ng-select-container .ng-value-container .ng-value-label {
  display: none !important;
}
.ng-select-assign.ng-select-multiple .ng-dropdown-panel {
  left: auto;
  right: 0;
}
.ng-select-assign.ng-select-multiple .ng-dropdown-panel .clear {
  display: none !important;
}

.ng-select-input .ng-select-container {
  display: flex !important;
  width: 100% !important;
  min-height: 40px;
  max-height: 40px;
  height: 40px !important;
  padding: 8px 12px !important;
  border-radius: 8px !important;
  border: none !important;
  box-shadow: none !important;
  cursor: pointer !important;
  transition: all 0.3s ease;
  background: #f2f2f2 !important;
}
.ng-select-input .ng-select-container .ng-value-container {
  padding-left: 0;
  padding-right: 0;
}
.ng-select-input .ng-select-container .ng-value-container .ng-input {
  display: none !important;
}
.ng-select-input .ng-select-container .ng-value-container .ng-value {
  padding: 0;
  display: flex;
  align-items: center;
  width: 100%;
}
.ng-select-input .ng-select-container .ng-value-container .ng-value .ng-value-label {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #050505;
  text-transform: none;
  transition: color 0.3s ease;
  width: 100%;
}
.ng-select-input .ng-select-container .ng-value-container .ng-value .select-icon {
  margin-right: 8px;
}
.ng-select-input .ng-select-container .ng-value-container .ng-value .select-icon svg {
  width: 16px;
  height: 16px;
  display: block;
}
.ng-select-input .ng-select-container .ng-value-container .ng-value .select-icon svg path {
  fill: #050505;
}
.ng-select-input .ng-select-container .ng-value-container .ng-value .arrow {
  display: block;
  margin-left: 12px;
}
.ng-select-input .ng-select-container .ng-value-container .ng-value .arrow .arrow-icon svg {
  width: 16px;
  height: 16px;
  display: block;
}
.ng-select-input .ng-select-container .ng-value-container .ng-value .arrow .arrow-icon svg path {
  fill: #050505;
}
.ng-select-input .ng-select-container:hover .ng-value-container .ng-value .ng-value-label {
  color: #050505;
}
.ng-select-input .ng-select-container:hover svg path {
  fill: #050505;
}
.ng-select-input.ng-select-opened .ng-select-container svg path {
  fill: #050505;
}
.ng-select-input .ng-dropdown-panel {
  width: 100%;
}

.ng-select-input-small .ng-select-container {
  display: flex;
  width: 100%;
  min-height: 32px;
  max-height: 32px;
  height: 32px;
  padding: 8px !important;
  border-radius: 8px !important;
  border: none !important;
  box-shadow: none !important;
  cursor: pointer !important;
  transition: background 0.3s ease;
  background: #f8f8f8 !important;
}
.ng-select-input-small .ng-select-container .ng-value-container {
  padding-left: 0;
  padding-right: 0;
}
.ng-select-input-small .ng-select-container .ng-value-container .ng-input {
  display: none !important;
}
.ng-select-input-small .ng-select-container .ng-value-container .ng-value {
  padding: 0;
  display: flex;
  align-items: center;
  width: 100%;
}
.ng-select-input-small .ng-select-container .ng-value-container .ng-value .ng-value-label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #050505;
  text-transform: none;
  transition: color 0.3s ease;
  width: 100%;
}
.ng-select-input-small .ng-select-container .ng-value-container .ng-value .arrow {
  margin-left: 0;
}
.ng-select-input-small .ng-select-container .ng-value-container .ng-value .arrow .arrow-icon svg {
  width: 12px;
  height: 12px;
}
.ng-select-input-small .ng-select-container .ng-value-container .ng-value ui-flag-icon {
  margin-right: 8px;
}
.ng-select-input-small .ng-select-container .ng-value-container .ng-value ui-flag-icon .flag-icon {
  width: 22px;
  height: 16px;
}
.ng-select-input-small .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 200px;
}
.ng-select-input-small.ng-select-opened .ng-select-container {
  background: #f2f2f2 !important;
}
.ng-select-input-small .ng-dropdown-panel {
  min-width: 160px !important;
  width: auto;
  left: auto;
  right: 0;
  z-index: 9999;
}

.ng-select-input-big .ng-select-container {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 48px;
  max-height: 48px;
  height: 48px;
  padding: 12px 16px;
  border-radius: 12px !important;
  border: none !important;
  box-shadow: none !important;
  cursor: pointer !important;
  transition: background 0.3s ease;
  background: #f2f2f2 !important;
}
.ng-select-input-big .ng-select-container .ng-value-container {
  padding-left: 0;
  padding-right: 0;
}
.ng-select-input-big .ng-select-container .ng-value-container .ng-input {
  display: none !important;
  top: 0 !important;
  bottom: 0;
  padding: 12px 16px !important;
}
.ng-select-input-big .ng-select-container .ng-value-container .ng-input input {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #050505;
  cursor: pointer !important;
}
.ng-select-input-big .ng-select-container .ng-value-container .ng-input input:focus {
  cursor: initial !important;
}
.ng-select-input-big .ng-select-container .ng-value-container .ng-value {
  padding: 0;
  display: flex;
  align-items: center;
  width: 100%;
}
.ng-select-input-big .ng-select-container .ng-value-container .ng-value .ng-value-label {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #050505;
  text-transform: none;
  transition: color 0.3s ease;
  width: 100%;
}
.ng-select-input-big .ng-select-container .ng-value-container .ng-value ui-flag-icon span {
  width: 28px;
  line-height: 20px;
  height: 20px;
  background-size: cover;
  background-position: center center;
  border-radius: 4px;
}
.ng-select-input-big .ng-select-container .ng-value-container .ng-value .option-icon {
  margin-right: 12px;
}
.ng-select-input-big .ng-select-container .ng-value-container .ng-value .option-icon svg {
  width: 16px;
  height: 16px;
  display: block;
}
.ng-select-input-big .ng-select-container .ng-value-container .ng-value .option-icon + span {
  line-height: 25px;
}
.ng-select-input-big .ng-select-container .ng-value-container .ng-value .select-icon {
  margin-right: 8px;
}
.ng-select-input-big .ng-select-container .ng-value-container .ng-value .select-icon svg {
  width: 16px;
  height: 16px;
  display: block;
}
.ng-select-input-big .ng-select-container .ng-value-container .ng-value .select-icon svg path {
  fill: #050505;
}
.ng-select-input-big .ng-select-container .ng-value-container .ng-value .arrow {
  display: none;
}
.ng-select-input-big .ng-select-container .ng-value-container .ng-placeholder {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 26px;
  letter-spacing: -0.2px;
  color: #9e9e9e;
}
.ng-select-input-big .ng-select-container.ng-has-value .ng-value-container .ng-placeholder {
  display: none;
}
.ng-select-input-big .ng-select-container .ng-arrow-wrapper {
  width: 16px;
  height: 16px;
  padding: 0;
  display: block;
  top: auto !important;
  transition: transform 0.3s ease;
  transform-origin: center;
  will-change: transform;
  background-color: #050505;
  background-size: cover;
  -webkit-mask-image: url("/assets/icons/icons-arrow-down.svg");
  mask-image: url("/assets/icons/icons-arrow-down.svg");
  mask-size: 16px 16px;
  cursor: pointer;
  margin-left: 12px;
}
.ng-select-input-big .ng-select-container .ng-arrow-wrapper .ng-arrow {
  display: none;
}
.ng-select-input-big .ng-select-container:not(.ng-select-disabled) .ng-select-container:hover .ng-value-container .ng-value .ng-value-label {
  color: #050505;
}
.ng-select-input-big .ng-select-container:not(.ng-select-disabled) .ng-select-container:hover svg-icon:not(.option-icon) svg path {
  fill: #050505;
}
.ng-select-input-big:not(.ng-select-disabled) .ng-select-container:hover .ng-value-container .ng-value .ng-value-label {
  color: #050505;
}
.ng-select-input-big:not(.ng-select-disabled) .ng-select-container:hover svg-icon:not(.option-icon) svg path {
  fill: #050505;
}
.ng-select-input-big.ng-invalid.ng-dirty .ng-select-container {
  border: 2px solid #fa6857 !important;
  background-color: #feeeec !important;
  padding: 8px 12px;
}
.ng-select-input-big.ng-invalid.ng-dirty .ng-select-container .ng-value-container .ng-input {
  padding: 10px 14px !important;
}
.ng-select-input-big.ng-select-disabled .ng-select-container {
  cursor: default !important;
  background-color: #f8f8f8 !important;
}
.ng-select-input-big.ng-select-disabled .ng-select-container .ng-input input {
  cursor: default !important;
}
.ng-select-input-big.ng-select-disabled .ng-select-container .ng-arrow-wrapper {
  background-color: #b7b7b7 !important;
}
.ng-select-input-big.ng-select-disabled .ng-select-container .ng-value-container .ng-placeholder,
.ng-select-input-big.ng-select-disabled .ng-select-container .ng-value-container .ng-value .ng-value-label:not([class*=type]) {
  color: #b7b7b7 !important;
}
.ng-select-input-big.ng-select-disabled.ng-select-readonly .ng-select-container {
  padding: 12px 14px !important;
  background-color: #ffffff !important;
  border: 2px solid #ededed !important;
}
.ng-select-input-big.ng-select-disabled.ng-select-readonly .ng-select-container .ng-arrow-wrapper {
  display: none !important;
}
.ng-select-input-big.ng-select-disabled.ng-select-readonly .ng-select-container .ng-value-container .ng-placeholder,
.ng-select-input-big.ng-select-disabled.ng-select-readonly .ng-select-container .ng-value-container .ng-value .ng-value-label:not([class*=type]) {
  color: #050505 !important;
}
.ng-select-input-big.ng-select-opened .ng-select-container {
  background: #ededed !important;
}
.ng-select-input-big.ng-select-opened .ng-select-container svg-icon:not(.option-icon) path {
  fill: #050505;
}
.ng-select-input-big.ng-select-opened .ng-select-container .ng-arrow-wrapper {
  transform: rotate(180deg);
}
.ng-select-input-big.ng-select-searchable .ng-select-container .ng-value-container .ng-input {
  display: block !important;
}
.ng-select-input-big .ng-dropdown-panel .ng-dropdown-header + .ng-dropdown-panel-items {
  margin-top: -4px;
  margin-bottom: 4px;
}
.ng-select-input-big .ng-dropdown-panel .scrollable-content {
  height: calc(100% + 4px) !important;
  padding-bottom: 4px;
}

.ng-select-input-big.ng-select-multiple:not(.ng-select-tags) .ng-select-container {
  height: auto;
  max-height: 96px;
  transition: background 0.3s ease;
}
.ng-select-input-big.ng-select-multiple:not(.ng-select-tags) .ng-select-container .ng-value-container {
  padding: 0;
}
.ng-select-input-big.ng-select-multiple:not(.ng-select-tags) .ng-select-container .ng-value-container .ng-placeholder {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #050505;
}
.ng-select-input-big.ng-select-multiple:not(.ng-select-tags) .ng-select-container .ng-value-container .ng-input {
  display: none;
}
.ng-select-input-big.ng-select-multiple:not(.ng-select-tags) .ng-select-container .ng-value-container .ng-value {
  max-width: 215px;
  width: auto;
  overflow: hidden;
  display: flex;
  height: 32px;
  margin: 0 8px 8px 0;
  border-radius: 8px;
  background: #ffffff;
}
.ng-select-input-big.ng-select-multiple:not(.ng-select-tags) .ng-select-container .ng-value-container .ng-value .ng-value-clear {
  display: flex;
  align-items: center;
  border-left: 1px solid #f2f2f2 !important;
  padding: 0 8px;
  height: 100%;
  width: 29px;
  transition: all 0.3s ease;
}
.ng-select-input-big.ng-select-multiple:not(.ng-select-tags) .ng-select-container .ng-value-container .ng-value .ng-value-clear svg-icon svg {
  display: block;
  width: 12px;
  height: 12px;
}
.ng-select-input-big.ng-select-multiple:not(.ng-select-tags) .ng-select-container .ng-value-container .ng-value .ng-value-clear svg-icon svg path {
  fill: #767676;
  transition: all 0.3s ease;
}
.ng-select-input-big.ng-select-multiple:not(.ng-select-tags) .ng-select-container .ng-value-container .ng-value .ng-value-clear:hover svg-icon svg path {
  fill: #050505;
}
.ng-select-input-big.ng-select-multiple:not(.ng-select-tags) .ng-select-container .ng-value-container .ng-value .ng-value-label {
  order: 0;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 25px;
  letter-spacing: -0.2px;
  width: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 8px;
}
.ng-select-input-big.ng-select-multiple:not(.ng-select-tags) .ng-select-container .ng-value-container .ng-value .ng-value-label:before {
  vertical-align: 1px;
}
.ng-select-input-big.ng-select-multiple:not(.ng-select-tags) .ng-select-container .ng-arrow-wrapper {
  width: 16px;
  height: 16px;
  padding: 0;
  display: block;
  top: auto !important;
  transition: transform 0.3s ease;
  transform-origin: center;
  will-change: transform;
  background-color: #050505;
  background-size: cover;
  -webkit-mask-image: url("/assets/icons/icons-arrow-down.svg");
  mask-image: url("/assets/icons/icons-arrow-down.svg");
  mask-size: 16px 16px;
  cursor: pointer;
}
.ng-select-input-big.ng-select-multiple:not(.ng-select-tags) .ng-select-container .ng-arrow-wrapper .ng-arrow {
  display: none;
}
.ng-select-input-big.ng-select-multiple:not(.ng-select-tags) .ng-select-container.ng-has-value {
  padding: 8px 12px 0 8px;
}
.ng-select-input-big.ng-select-multiple:not(.ng-select-tags) .ng-select-container.ng-has-value .ng-arrow-wrapper {
  margin-bottom: 8px;
}
.ng-select-input-big.ng-select-multiple:not(.ng-select-tags).ng-select-opened .ng-select-container .ng-arrow-wrapper {
  transform: rotate(180deg);
}
.ng-select-input-big.ng-select-multiple:not(.ng-select-tags) .ng-dropdown-panel {
  width: 100%;
}

.ng-select-pill .ng-select-container {
  display: inline-flex !important;
  width: auto !important;
  min-height: 32px;
  max-height: 32px;
  height: 32px !important;
  padding: 8px 11px !important;
  border-radius: 16px !important;
  border: solid 1px #e0e0e0 !important;
  box-shadow: none !important;
  cursor: pointer !important;
  transition: all 0.3s ease;
  background: transparent !important;
}
.ng-select-pill .ng-select-container .ng-value-container {
  padding-left: 0;
  padding-right: 0;
}
.ng-select-pill .ng-select-container .ng-value-container .ng-input {
  display: none !important;
}
.ng-select-pill .ng-select-container .ng-value-container .ng-placeholder {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: -0.1px;
  color: #4d4d4d;
  text-transform: none;
  transition: color 0.3s ease;
}
.ng-select-pill .ng-select-container .ng-value-container .ng-value {
  padding: 0;
  display: flex;
  align-items: center;
}
.ng-select-pill .ng-select-container .ng-value-container .ng-value .option-icon {
  margin-right: 8px;
}
.ng-select-pill .ng-select-container .ng-value-container .ng-value .option-icon svg {
  width: 16px;
  height: 16px;
  display: block;
}
.ng-select-pill .ng-select-container .ng-value-container .ng-value .ng-value-label {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: -0.1px;
  color: #4d4d4d;
  text-transform: none;
  transition: color 0.3s ease;
}
.ng-select-pill .ng-select-container .ng-value-container .ng-value .select-icon svg {
  width: 16px;
  height: 16px;
  display: block;
  margin-right: 8px;
}
.ng-select-pill .ng-select-container .ng-value-container .ng-value .select-icon svg path {
  fill: #4d4d4d;
}
.ng-select-pill .ng-select-container .ng-value-container .ng-value .arrow {
  display: block;
  margin-left: 12px;
}
.ng-select-pill .ng-select-container .ng-value-container .ng-value .arrow .arrow-icon svg {
  width: 12px;
  height: 12px;
  display: block;
}
.ng-select-pill .ng-select-container .ng-value-container .ng-value .arrow .arrow-icon svg path {
  fill: #4d4d4d;
}
.ng-select-pill .ng-select-container .ng-spinner-loader {
  margin-left: 8px;
}
.ng-select-pill .ng-select-container:hover {
  border: solid 1px #cacaca !important;
}
.ng-select-pill .ng-select-container:hover .ng-value-container .ng-value .ng-value-label {
  color: #050505;
}
.ng-select-pill .ng-select-container:hover svg path {
  fill: #050505 !important;
}
.ng-select-pill.ng-select-opened .ng-select-container {
  border: solid 1px #e5e5e5 !important;
  background-color: #e5e5e5 !important;
  z-index: 1 !important;
}
.ng-select-pill.ng-select-opened .ng-select-container .arrow .arrow-icon {
  transform: rotate(180deg);
}
.ng-select-pill.ng-select-opened .ng-select-container svg path {
  fill: #050505;
}

.ng-select-pill-tertiary .ng-select-container {
  display: inline-flex !important;
  width: auto !important;
  min-height: 32px;
  max-height: 32px;
  height: 32px !important;
  padding: 8px 12px !important;
  border-radius: 360px !important;
  border: none !important;
  background: #ffffff !important;
  box-shadow: none !important;
  cursor: pointer !important;
  transition: all 0.3s ease;
}
.ng-select-pill-tertiary .ng-select-container .ng-value-container {
  padding: 0 !important;
}
.ng-select-pill-tertiary .ng-select-container .ng-value-container .ng-input {
  display: none !important;
}
.ng-select-pill-tertiary .ng-select-container .ng-value-container .ng-placeholder {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: -0.1px;
  color: #4d4d4d;
  text-transform: none;
  transition: color 0.3s ease;
}
.ng-select-pill-tertiary .ng-select-container .ng-value-container .ng-value {
  padding: 0;
  display: flex;
  align-items: center;
}
.ng-select-pill-tertiary .ng-select-container .ng-value-container .ng-value .ng-value-label {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: -0.2px;
  color: #050505;
  text-transform: none;
  transition: color 0.3s ease;
}
.ng-select-pill-tertiary .ng-select-container .ng-value-container .ng-value .arrow {
  display: block;
  margin-left: 12px;
}
.ng-select-pill-tertiary .ng-select-container .ng-value-container .ng-value .arrow svg-icon {
  transform: rotate(0) !important;
  display: block;
  transition: all 0.3s ease;
}
.ng-select-pill-tertiary .ng-select-container .ng-value-container .ng-value .arrow svg-icon svg {
  width: 12px;
  height: 12px;
  display: block;
}
.ng-select-pill-tertiary .ng-select-container .ng-value-container .ng-value .arrow svg-icon svg path {
  fill: #050505;
}
.ng-select-pill-tertiary .ng-select-container:hover {
  background: #f5f5f5 !important;
}
.ng-select-pill-tertiary.ng-select-opened .ng-select-container {
  background-color: #ebebeb !important;
  z-index: 1 !important;
}
.ng-select-pill-tertiary.ng-select-opened .ng-select-container .arrow svg-icon {
  transform: rotate(180deg) !important;
}
.ng-select-pill-tertiary-small .ng-select-container {
  min-height: 32px !important;
  max-height: 32px !important;
  height: 32px !important;
  padding: 8px 12px !important;
}

.ng-select-pill-secondary .ng-select-container {
  display: flex !important;
  width: 100% !important;
  min-height: 40px;
  max-height: 40px;
  height: 40px !important;
  padding: 12px !important;
  border-radius: 360px !important;
  border: none !important;
  box-shadow: none !important;
  cursor: pointer !important;
  transition: all 0.3s ease;
  background: #f2f2f2 !important;
}
.ng-select-pill-secondary .ng-select-container .ng-value-container {
  padding-left: 0;
  padding-right: 0;
}
.ng-select-pill-secondary .ng-select-container .ng-value-container .ng-input {
  display: none !important;
}
.ng-select-pill-secondary .ng-select-container .ng-value-container .ng-value {
  padding: 0;
  display: flex;
  align-items: center;
  width: 100%;
}
.ng-select-pill-secondary .ng-select-container .ng-value-container .ng-value .option-icon svg {
  width: 16px;
  height: 16px;
  display: block;
}
.ng-select-pill-secondary .ng-select-container .ng-value-container .ng-value .ng-value-label {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.2px;
  color: #050505;
  text-transform: none;
  transition: color 0.3s ease;
  width: 100%;
}
.ng-select-pill-secondary .ng-select-container .ng-value-container .ng-value .select-icon {
  margin-right: 8px;
}
.ng-select-pill-secondary .ng-select-container .ng-value-container .ng-value .select-icon svg {
  width: 16px;
  height: 16px;
  display: block;
}
.ng-select-pill-secondary .ng-select-container .ng-value-container .ng-value .select-icon svg path {
  fill: #050505;
}
.ng-select-pill-secondary .ng-select-container .ng-value-container .ng-value .arrow {
  display: block;
  margin-left: 16px;
}
.ng-select-pill-secondary .ng-select-container .ng-value-container .ng-value .arrow .arrow-icon svg {
  width: 16px;
  height: 16px;
  display: block;
}
.ng-select-pill-secondary .ng-select-container .ng-value-container .ng-value .arrow .arrow-icon svg path {
  fill: #050505;
}
.ng-select-pill-secondary .ng-select-container:hover {
  background: #ededed !important;
}
.ng-select-pill-secondary .ng-select-container:hover .ng-value-container .ng-value .ng-value-label {
  color: #050505;
}
.ng-select-pill-secondary .ng-select-container:hover svg-icon:not(.option-icon) svg path {
  fill: #050505;
}
.ng-select-pill-secondary.ng-select-opened .ng-select-container {
  background: #e5e5e5 !important;
}
.ng-select-pill-secondary.ng-select-opened .ng-select-container svg-icon:not(.option-icon) svg path {
  fill: #050505;
}
.ng-select-pill-secondary-small .ng-select-container {
  min-height: 32px !important;
  max-height: 32px !important;
  height: 32px !important;
  padding: 8px !important;
}

.ng-select-pill-big .ng-select-container {
  display: inline-flex !important;
  width: 100% !important;
  min-height: 40px;
  max-height: 40px;
  height: 40px !important;
  padding: 12px 16px !important;
  border-radius: 20px !important;
  border: solid 1px #e0e0e0 !important;
  box-shadow: none !important;
  cursor: pointer !important;
  transition: all 0.3s ease;
  background: transparent !important;
}
.ng-select-pill-big .ng-select-container .ng-value-container {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}
.ng-select-pill-big .ng-select-container .ng-value-container .ng-input,
.ng-select-pill-big .ng-select-container .ng-value-container .arrow {
  display: none;
}
.ng-select-pill-big .ng-select-container .ng-value-container .ng-placeholder {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: -0.1px;
  color: #4d4d4d;
  text-transform: none;
  transition: color 0.3s ease;
}
.ng-select-pill-big .ng-select-container .ng-value-container .ng-value {
  padding: 0;
  display: flex;
  align-items: center;
  width: 100%;
}
.ng-select-pill-big .ng-select-container .ng-value-container .ng-value .ng-value-label {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: -0.1px;
  color: #4d4d4d;
  text-transform: none;
  transition: color 0.3s ease;
}
.ng-select-pill-big .ng-select-container .ng-arrow-wrapper {
  width: 12px;
  height: 12px;
  padding: 0;
  display: block;
  top: auto !important;
  transition: transform 0.3s ease;
  transform-origin: center;
  will-change: transform;
  background-color: #050505;
  background-size: cover;
  -webkit-mask-image: url("/assets/icons/icons-arrow-down.svg");
  mask-image: url("/assets/icons/icons-arrow-down.svg");
  mask-size: 12px 12px;
  cursor: pointer;
  margin-left: auto;
}
.ng-select-pill-big .ng-select-container .ng-arrow-wrapper .ng-arrow {
  display: none;
}
.ng-select-pill-big .ng-select-container:hover {
  border: solid 1px #cacaca !important;
}
.ng-select-pill-big .ng-select-container:hover .ng-value-container .ng-value .ng-value-label {
  color: #050505;
}
.ng-select-pill-big.ng-select-opened .ng-select-container {
  border: solid 1px #e5e5e5 !important;
  background-color: #e5e5e5 !important;
  z-index: 1 !important;
}
.ng-select-pill-big.ng-select-opened .ng-select-container .ng-arrow-wrapper {
  transform: rotate(180deg);
}

.ng-select-tags.ng-select-multiple .ng-select-container {
  transition: background 0.3s ease;
  max-height: 100%;
  height: auto;
  padding: 8px 12px 8px 6px !important;
}
.ng-select-tags.ng-select-multiple .ng-select-container .ng-value-container {
  padding: 0;
  gap: 6px;
}
.ng-select-tags.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  max-width: 215px;
  width: auto;
  overflow: hidden;
  display: flex;
  height: 32px;
  border-radius: 16px;
  border: solid 1px #e5e5e5;
  background: #ffffff;
  padding-left: 10px;
  margin: 0;
}
.ng-select-tags.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-clear {
  display: flex;
  align-items: center;
  border-left: 1px solid #f2f2f2 !important;
  padding: 0 12px 0 7px;
  height: 100%;
  width: 30px;
  transition: all 0.3s ease;
}
.ng-select-tags.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-clear svg-icon svg {
  display: block;
  width: 12px;
  height: 12px;
}
.ng-select-tags.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-clear svg-icon svg path {
  fill: #767676;
  transition: all 0.3s ease;
}
.ng-select-tags.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-clear:hover svg-icon svg path {
  fill: #050505;
}
.ng-select-tags.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  order: 0;
  font-family: "Manrope", sans-serif;
  width: auto;
  padding: 0 8px 0 0;
  font-size: 11px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: normal;
  color: #050505;
  text-transform: uppercase;
}
.ng-select-tags.ng-select-multiple .ng-select-container .ng-value-container .ng-value .tags-icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.ng-select-tags.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #050505;
  display: block !important;
  padding: 0 !important;
}
.ng-select-tags.ng-select-multiple .ng-select-container .ng-arrow-wrapper {
  width: 16px;
  height: 16px;
  padding: 0;
  display: block;
  top: auto !important;
  transition: transform 0.3s ease;
  transform-origin: center;
  will-change: transform;
  background-color: #050505;
  background-size: cover;
  -webkit-mask-image: url("/assets/icons/icons-arrow-down.svg");
  mask-image: url("/assets/icons/icons-arrow-down.svg");
  mask-size: 16px 16px;
  cursor: pointer;
}
.ng-select-tags.ng-select-multiple .ng-select-container .ng-arrow-wrapper .ng-arrow {
  display: none;
}
.ng-select-tags.ng-select-multiple .ng-select-container.ng-has-value {
  padding: 0 12px 0 8px;
}
.ng-select-tags.ng-select-multiple.ng-select-opened .ng-select-container .ng-arrow-wrapper {
  transform: rotate(180deg);
}
.ng-select-tags.ng-select-multiple .ng-dropdown-panel {
  width: 100%;
}
.ng-select-tags.ng-select-multiple .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:first-child {
  margin-top: 4px;
}
.ng-select-tags.ng-select-multiple .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .tags-icon {
  width: 16px;
  height: 16px;
  margin-right: 12px;
}
.ng-select-tags.ng-select-multiple .ng-dropdown-panel .ng-dropdown-panel-items .ng-option span {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 25px;
  letter-spacing: -0.2px;
  color: #050505;
}

.ng-select-tags.ng-select-emails.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  max-width: 100%;
}
.ng-select-tags.ng-select-emails.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  text-transform: none;
}
.ng-select-tags.ng-select-emails.ng-select-multiple .ng-select-container .ng-arrow-wrapper {
  display: none;
}
.ng-select-tags.ng-select-emails.ng-select-multiple .ng-dropdown-panel {
  display: none;
}
.ng-select-tags.ng-select-emails.ng-select-multiple.ng-invalid.ng-dirty .ng-select-container {
  padding: 0 10px 0 4px !important;
}
.ng-select-tags.ng-select-emails.ng-select-multiple.ng-invalid.ng-dirty .ng-select-container .ng-value-container .ng-input {
  padding: 0 !important;
}

.ng-select-sh .ng-select-focused .ng-input {
  cursor: text !important;
}
.ng-select-sh .ng-select-focused .ng-input input {
  cursor: initial !important;
}

.emoji-mart,
.emoji-mart * {
  box-sizing: border-box;
  line-height: 1.15;
}

.emoji-mart {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  font-size: 16px;
  display: inline-block;
  color: #222427;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff;
}

.emoji-mart .emoji-mart-emoji {
  padding: 6px;
}

.emoji-mart-bar {
  border: 0 solid #d9d9d9;
}

.emoji-mart-bar:first-child {
  border-bottom-width: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.emoji-mart-bar:last-child {
  border-top-width: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.emoji-mart-anchors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 6px;
  line-height: 0;
}

.emoji-mart-anchor {
  position: relative;
  display: block;
  flex: 1 1 auto;
  color: #858585;
  text-align: center;
  padding: 12px 4px;
  overflow: hidden;
  transition: color 0.1s ease-out;
  margin: 0;
  box-shadow: none;
  background: none;
  border: none;
}

.emoji-mart-anchor:focus {
  outline: 0;
}

.emoji-mart-anchor:hover,
.emoji-mart-anchor:focus,
.emoji-mart-anchor-selected {
  color: #464646;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  bottom: 0;
}

.emoji-mart-anchor-bar {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #464646;
}

.emoji-mart-anchors i {
  display: inline-block;
  width: 100%;
  max-width: 22px;
}

.emoji-mart-anchors svg,
.emoji-mart-anchors img {
  fill: currentColor;
  height: 18px;
}

.emoji-mart-scroll {
  overflow-y: scroll;
  height: 270px;
  padding: 0 6px 6px 6px;
  will-change: transform; /* avoids "repaints on scroll" in mobile Chrome */
}

.emoji-mart-search {
  margin-top: 6px;
  padding: 0 6px;
  position: relative;
}

.emoji-mart-search input {
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 5px 25px 6px 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: 0;
}

.emoji-mart-search input,
.emoji-mart-search input::-webkit-search-decoration,
.emoji-mart-search input::-webkit-search-cancel-button,
.emoji-mart-search input::-webkit-search-results-button,
.emoji-mart-search input::-webkit-search-results-decoration {
  /* remove webkit/blink styles for <input type="search">
   * via https://stackoverflow.com/a/9422689 */
  -webkit-appearance: none;
}

.emoji-mart-search-icon {
  position: absolute;
  top: 3px;
  right: 11px;
  z-index: 2;
  padding: 2px 5px 1px;
  border: none;
  background: none;
}

.emoji-mart-category .emoji-mart-emoji span {
  z-index: 1;
  position: relative;
  text-align: center;
  cursor: default;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
  z-index: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  border-radius: 100%;
}

.emoji-mart-category-label {
  z-index: 2;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.emoji-mart-category-label span {
  display: block;
  width: 100%;
  font-weight: 500;
  padding: 5px 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.95);
}

.emoji-mart-category-list {
  margin: 0;
  padding: 0;
}

.emoji-mart-category-list li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.emoji-mart-emoji {
  position: relative;
  display: inline-block;
  font-size: 0;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
}

.emoji-mart-emoji-native {
  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji";
}

.emoji-mart-no-results {
  font-size: 14px;
  text-align: center;
  padding-top: 70px;
  color: #858585;
}

.emoji-mart-no-results .emoji-mart-category-label {
  display: none;
}

.emoji-mart-no-results .emoji-mart-no-results-label {
  margin-top: 0.2em;
}

.emoji-mart-no-results .emoji-mart-emoji:hover:before {
  content: none;
}

.emoji-mart-preview {
  position: relative;
  height: 70px;
}

.emoji-mart-preview-emoji,
.emoji-mart-preview-data,
.emoji-mart-preview-skins {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.emoji-mart-preview-emoji {
  left: 12px;
}

.emoji-mart-preview-data {
  left: 68px;
  right: 12px;
  word-break: break-all;
}

.emoji-mart-preview-skins {
  right: 30px;
  text-align: right;
}

.emoji-mart-preview-skins.custom {
  right: 10px;
  text-align: right;
}

.emoji-mart-preview-name {
  font-size: 14px;
}

.emoji-mart-preview-shortname {
  font-size: 12px;
  color: #888;
}

.emoji-mart-preview-shortname + .emoji-mart-preview-shortname,
.emoji-mart-preview-shortname + .emoji-mart-preview-emoticon,
.emoji-mart-preview-emoticon + .emoji-mart-preview-emoticon {
  margin-left: 0.5em;
}

.emoji-mart-preview-emoticon {
  font-size: 11px;
  color: #bbb;
}

.emoji-mart-title span {
  display: inline-block;
  vertical-align: middle;
}

.emoji-mart-title .emoji-mart-emoji {
  padding: 0;
}

.emoji-mart-title-label {
  color: #999A9C;
  font-size: 26px;
  font-weight: 300;
}

.emoji-mart-skin-swatches {
  font-size: 0;
  padding: 2px 0;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  background-color: #fff;
}

.emoji-mart-skin-swatches.custom {
  font-size: 0;
  border: none;
  background-color: #fff;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch {
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch.selected:after {
  opacity: 0.75;
}

.emoji-mart-skin-swatch {
  display: inline-block;
  width: 0;
  vertical-align: middle;
  transition-property: width, padding;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
}

.emoji-mart-skin-swatch:nth-child(1) {
  transition-delay: 0s;
}

.emoji-mart-skin-swatch:nth-child(2) {
  transition-delay: 0.03s;
}

.emoji-mart-skin-swatch:nth-child(3) {
  transition-delay: 0.06s;
}

.emoji-mart-skin-swatch:nth-child(4) {
  transition-delay: 0.09s;
}

.emoji-mart-skin-swatch:nth-child(5) {
  transition-delay: 0.12s;
}

.emoji-mart-skin-swatch:nth-child(6) {
  transition-delay: 0.15s;
}

.emoji-mart-skin-swatch.selected {
  position: relative;
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatch.selected:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  margin: -2px 0 0 -2px;
  background-color: #fff;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.emoji-mart-skin-swatch.custom {
  display: inline-block;
  width: 0;
  height: 38px;
  overflow: hidden;
  vertical-align: middle;
  transition-property: width, height;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
  cursor: default;
}

.emoji-mart-skin-swatch.custom.selected {
  position: relative;
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatch.custom.selected:after {
  content: "";
  width: 0;
  height: 0;
}

.emoji-mart-skin-swatches.custom .emoji-mart-skin-swatch.custom:hover {
  background-color: #f4f4f4;
  border-radius: 10%;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom {
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom.selected:after {
  opacity: 0.75;
}

.emoji-mart-skin-text.opened {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  color: #888;
  font-size: 11px;
  padding: 5px 2px;
  width: 95px;
  height: 40px;
  border-radius: 10%;
  background-color: #fff;
}

.emoji-mart-skin {
  display: inline-block;
  width: 100%;
  padding-top: 100%;
  max-width: 12px;
  border-radius: 100%;
}

.emoji-mart-skin-tone-1 {
  background-color: #ffc93a;
}

.emoji-mart-skin-tone-2 {
  background-color: #fadcbc;
}

.emoji-mart-skin-tone-3 {
  background-color: #e0bb95;
}

.emoji-mart-skin-tone-4 {
  background-color: #bf8f68;
}

.emoji-mart-skin-tone-5 {
  background-color: #9b643d;
}

.emoji-mart-skin-tone-6 {
  background-color: #594539;
}

/* For screenreaders only, via https://stackoverflow.com/a/19758620 */
.emoji-mart-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
 * Dark mode styles
 */
.emoji-mart-dark {
  color: #fff;
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-bar {
  border-color: #555453;
}

.emoji-mart-dark .emoji-mart-search input {
  color: #fff;
  border-color: #555453;
  background-color: #2f2f2f;
}

.emoji-mart-dark .emoji-mart-search-icon svg {
  fill: #fff;
}

.emoji-mart-dark .emoji-mart-category .emoji-mart-emoji:hover:before {
  background-color: #444;
}

.emoji-mart-dark .emoji-mart-category-label span {
  background-color: #222;
  color: #fff;
}

.emoji-mart-dark .emoji-mart-skin-swatches {
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-anchor:hover,
.emoji-mart-dark .emoji-mart-anchor:focus,
.emoji-mart-dark .emoji-mart-anchor-selected {
  color: #bfbfbf;
}

quill-editor {
  display: flex !important;
  flex-direction: column-reverse;
}
quill-editor *:focus {
  outline: none;
}
quill-editor .ql-tooltip {
  z-index: 99;
}
quill-editor .ql-toolbar.ql-snow {
  height: 40px;
  border-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: #f8f8f8;
  padding: 6px 8px;
  border: none;
}
quill-editor .ql-toolbar.ql-snow .ql-formats {
  position: relative;
  margin-right: 9px;
}
quill-editor .ql-toolbar.ql-snow .ql-formats::after {
  content: "";
  width: 1px;
  height: 16px;
  margin: 6px 0 6px 4px;
  background-color: #e0e0e0;
  display: inline-flex;
}
quill-editor .ql-toolbar.ql-snow .ql-formats:last-child::after {
  display: none;
}
quill-editor .ql-toolbar.ql-snow .ql-formats > button, quill-editor .ql-toolbar.ql-snow .ql-formats > span, quill-editor .ql-toolbar.ql-snow .ql-formats > select {
  margin-right: 4px;
}
quill-editor .ql-toolbar.ql-snow .ql-formats > button {
  height: 28px;
  width: 28px;
  border-radius: 6px;
  background: transparent;
  transition: all 0.3s ease;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px;
}
quill-editor .ql-toolbar.ql-snow .ql-formats > button.ql-bold {
  background-image: url("/assets/icons/icons-typo-b.svg");
}
quill-editor .ql-toolbar.ql-snow .ql-formats > button.ql-italic {
  background-image: url("/assets/icons/icons-typo-i.svg");
}
quill-editor .ql-toolbar.ql-snow .ql-formats > button.ql-underline {
  background-image: url("/assets/icons/icons-typo-u.svg");
}
quill-editor .ql-toolbar.ql-snow .ql-formats > button.ql-link::after {
  content: "";
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("/assets/icons/icons-link.svg");
  mask-image: url("/assets/icons/icons-link.svg");
  mask-size: 16px 16px;
  mask-repeat: no-repeat;
  background-color: #050505;
}
quill-editor .ql-toolbar.ql-snow .ql-formats > button.ql-mention::after {
  content: "";
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("/assets/icons/icons-hash.svg");
  mask-image: url("/assets/icons/icons-hash.svg");
  mask-size: 16px 16px;
  mask-repeat: no-repeat;
  background-color: #050505;
}
quill-editor .ql-toolbar.ql-snow .ql-formats > button.ql-header::after {
  content: "";
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("/assets/icons/icons-typo-h-3.svg");
  mask-image: url("/assets/icons/icons-typo-h-3.svg");
  mask-size: 16px 16px;
  mask-repeat: no-repeat;
  background-color: #050505;
}
quill-editor .ql-toolbar.ql-snow .ql-formats > button.ql-library::after, quill-editor .ql-toolbar.ql-snow .ql-formats > button.ql-image::after {
  content: "";
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("/assets/icons/icons-image.svg");
  mask-image: url("/assets/icons/icons-image.svg");
  mask-size: 16px 16px;
  mask-repeat: no-repeat;
  background-color: #050505;
}
quill-editor .ql-toolbar.ql-snow .ql-formats > button:hover, quill-editor .ql-toolbar.ql-snow .ql-formats > button.ql-active {
  background-color: #ededed;
}
quill-editor .ql-toolbar.ql-snow .ql-formats > button svg {
  display: none;
}
quill-editor .ql-toolbar.ql-snow .ql-formats .ql-color {
  height: 28px;
  width: 28px;
  border-radius: 6px !important;
  transition: all 0.3s ease;
  overflow: hidden;
}
quill-editor .ql-toolbar.ql-snow .ql-formats .ql-color:hover, quill-editor .ql-toolbar.ql-snow .ql-formats .ql-color.ql-expanded {
  background-color: #ededed;
}
quill-editor .ql-toolbar.ql-snow .ql-formats .ql-color .ql-picker-label {
  height: 28px;
  width: 28px;
  background: transparent;
  background-image: url("/assets/icons/icons-typo-colors.svg");
  margin-right: 4px;
  border: none !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px;
}
quill-editor .ql-toolbar.ql-snow .ql-formats .ql-color .ql-picker-label svg {
  display: none;
}
quill-editor .ql-toolbar.ql-snow .ql-formats .ql-color .ql-picker-options {
  display: flex !important;
  flex-shrink: 0;
  opacity: 0;
  visibility: hidden;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px rgba(0, 0, 0, 0.04);
  background-color: #fff;
  width: auto;
  margin-left: -89px;
  left: 50%;
  top: 100%;
  margin-top: -4px;
  transition: all 0.3s ease;
  transform: scale(0.99);
}
quill-editor .ql-toolbar.ql-snow .ql-formats .ql-color .ql-picker-options .ql-picker-item {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin: 0 8px 0 0;
  border: none;
  transition: all 0.3s ease;
}
quill-editor .ql-toolbar.ql-snow .ql-formats .ql-color .ql-picker-options .ql-picker-item:last-child {
  margin-right: 0;
}
quill-editor .ql-toolbar.ql-snow .ql-formats .ql-color .ql-picker-options .ql-picker-item:hover {
  box-shadow: 0 0 0 4px #ededed;
}
quill-editor .ql-toolbar.ql-snow .ql-formats .ql-color.ql-expanded {
  overflow: visible;
}
quill-editor .ql-toolbar.ql-snow .ql-formats .ql-color.ql-expanded .ql-picker-options {
  opacity: 1;
  visibility: visible;
  margin-top: -4px;
  transform: scale(1);
}
quill-editor .ql-toolbar.ql-snow .ql-formats .ql-emoji {
  height: 28px;
  width: 28px;
  border-radius: 6px !important;
  transition: all 0.3s ease;
  overflow: hidden;
}
quill-editor .ql-toolbar.ql-snow .ql-formats .ql-emoji:hover, quill-editor .ql-toolbar.ql-snow .ql-formats .ql-emoji.ql-expanded {
  background-color: #ededed;
}
quill-editor .ql-toolbar.ql-snow .ql-formats .ql-emoji .ql-picker-label {
  height: 28px;
  width: 28px;
  background: transparent;
  background-image: url("/assets/icons/icons-emoticon.svg");
  margin-right: 4px;
  border: none !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px;
}
quill-editor .ql-toolbar.ql-snow .ql-formats .ql-emoji .ql-picker-label svg {
  display: none;
}
quill-editor .ql-toolbar.ql-snow .ql-formats .ql-emoji .ql-picker-options {
  display: flex !important;
  flex-shrink: 0;
  opacity: 0;
  visibility: hidden;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px rgba(0, 0, 0, 0.04);
  background-color: #fff;
  width: auto;
  margin-left: -89px;
  left: 50%;
  top: 100%;
  margin-top: -4px;
  transition: all 0.3s ease;
  transform: scale(0.99);
}
quill-editor .ql-toolbar.ql-snow .ql-formats .ql-emoji .ql-picker-options .ql-picker-item {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin: 0 8px 0 0;
  border: none;
  transition: all 0.3s ease;
}
quill-editor .ql-toolbar.ql-snow .ql-formats .ql-emoji .ql-picker-options .ql-picker-item:last-child {
  margin-right: 0;
}
quill-editor .ql-toolbar.ql-snow .ql-formats .ql-emoji .ql-picker-options .ql-picker-item:hover {
  box-shadow: 0 0 0 4px #ededed;
}
quill-editor .ql-toolbar.ql-snow .ql-formats .ql-emoji.ql-expanded {
  overflow: visible;
}
quill-editor .ql-toolbar.ql-snow .ql-formats .ql-emoji.ql-expanded .ql-picker-options {
  opacity: 1;
  visibility: visible;
  margin-top: -4px;
  transform: scale(1);
}
quill-editor .ql-container.ql-snow {
  border: none;
}
quill-editor .ql-container.ql-snow .ql-editor {
  height: 100%;
  padding: 12px 16px;
  background: #f2f2f2;
  font-size: 16px;
  font-weight: 400;
  border-radius: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  font-family: "Manrope", "Helvetica", Poppins, sans-serif;
  line-height: 22px;
  color: #050505;
  transition: background-color 0.3s ease;
  min-height: 48px;
  max-height: 500px;
}
quill-editor .ql-container.ql-snow .ql-editor * {
  font-family: "Manrope", "Helvetica", Poppins, sans-serif;
}
quill-editor .ql-container.ql-snow .ql-editor a {
  color: #18cff7;
}
quill-editor .ql-container.ql-snow .ql-editor img {
  margin: 28px 0 0 0;
}
quill-editor .ql-container.ql-snow .ql-editor h3 {
  font-size: 18px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 26px;
  letter-spacing: -0.3px;
  color: #050505;
  margin: 28px 0 8px 0;
}
quill-editor .ql-container.ql-snow .ql-editor h3:first-child {
  margin-top: 0;
}
quill-editor .ql-container.ql-snow .ql-editor:focus {
  background: #ededed;
}
quill-editor .ql-container.ql-snow .ql-editor.ql-blank::before {
  content: none;
}
quill-editor .ql-container.ql-snow .ql-editor.ql-blank::after {
  font-family: "Manrope", sans-serif;
  content: attr(data-placeholder);
  pointer-events: none;
  display: block;
  margin-top: -1.38rem;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #b7b7b7;
}
quill-editor.ng-dirty.ng-invalid .ql-container.ql-snow .ql-editor, quill-editor.input-error .ql-container.ql-snow .ql-editor {
  border: 2px solid #fa6857;
  padding: 12px 16px;
  background-color: #feeeec;
}
quill-editor.hide-toolbar .ql-toolbar {
  display: none !important;
}
quill-editor.hide-toolbar .ql-container.ql-snow .ql-editor {
  border-radius: 12px;
}
quill-editor.resizeable .ql-container.ql-snow .ql-editor {
  resize: vertical;
  overflow-y: scroll;
}
quill-editor.readonly .ql-toolbar {
  display: none !important;
}
quill-editor.readonly .ql-editor > * {
  cursor: default;
}
quill-editor.readonly .ql-container.ql-snow .ql-editor {
  border-radius: 12px;
  background: #fff;
  border: 2px solid #ededed;
}

ui-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
ui-form.counter {
  position: relative;
}
ui-form > label,
ui-form label.label {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.3px;
  color: #050505;
  margin-bottom: 8px;
  text-indent: 0;
  transition: color 0.3s ease;
  display: block;
}
ui-form > label em,
ui-form label.label em {
  letter-spacing: -0.2px;
  color: #f23918;
  margin-left: 2px;
}
ui-form > label span,
ui-form label.label span {
  float: right;
  color: #9e9e9e;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.1px;
}
ui-form .count {
  position: absolute;
  top: 5px;
  right: 0;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #4d4d4d;
}
ui-form ui-error-message {
  order: 2;
}
ui-form .form-text {
  order: 1;
}
ui-form .form-input,
ui-form .textarea-wrapper {
  height: 48px;
  border-radius: 12px;
  background-color: #f2f2f2;
  padding: 12px 14px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #050505;
  transition: background 0.3s ease, border 0.3s ease;
  width: 100%;
  border: solid 2px transparent;
}
ui-form .form-input.ng-dirty.ng-invalid, ui-form .form-input.ng-touched.ng-invalid,
ui-form .textarea-wrapper.ng-dirty.ng-invalid,
ui-form .textarea-wrapper.ng-touched.ng-invalid {
  border-color: #fa6857;
  background-color: #feeeec;
  padding: 12px 14px;
}
ui-form .form-input:focus, ui-form .form-input:focus-visible,
ui-form .textarea-wrapper:focus,
ui-form .textarea-wrapper:focus-visible {
  outline: none !important;
}
ui-form .form-input::-webkit-input-placeholder,
ui-form .textarea-wrapper::-webkit-input-placeholder {
  color: #9e9e9e;
}
ui-form .form-input:-moz-placeholder,
ui-form .textarea-wrapper:-moz-placeholder {
  color: #9e9e9e;
}
ui-form .form-input::-moz-placeholder,
ui-form .textarea-wrapper::-moz-placeholder {
  color: #9e9e9e;
}
ui-form .form-input:-ms-input-placeholder,
ui-form .textarea-wrapper:-ms-input-placeholder {
  color: #9e9e9e;
}
ui-form .form-input:disabled,
ui-form .textarea-wrapper:disabled {
  color: #767676;
  opacity: 1;
}
ui-form .form-input:read-only,
ui-form .textarea-wrapper:read-only {
  cursor: default;
}
ui-form .form-input[type=number],
ui-form .textarea-wrapper[type=number] {
  -moz-appearance: textfield;
}
ui-form .form-input[type=number]::-webkit-inner-spin-button, ui-form .form-input[type=number]::-webkit-outer-spin-button,
ui-form .textarea-wrapper[type=number]::-webkit-inner-spin-button,
ui-form .textarea-wrapper[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
ui-form .form-input.input-hint,
ui-form .textarea-wrapper.input-hint {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
ui-form .form-input.form-input-prefix,
ui-form .textarea-wrapper.form-input-prefix {
  width: auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 0;
  padding-top: 13px;
  display: flex;
  align-items: center;
  border-right: 0;
}
ui-form .form-input.form-input-prefix + input,
ui-form .textarea-wrapper.form-input-prefix + input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 0;
  border-left: 0;
}
ui-form .form-input.form-input-prefix + input:-webkit-autofill,
ui-form .textarea-wrapper.form-input-prefix + input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #f2f2f2 inset;
}
ui-form .textarea-wrapper {
  height: auto;
}
ui-form .textarea-wrapper textarea {
  border-radius: 0;
  min-height: 97px;
  padding: 0 !important;
  background-color: transparent !important;
  border-color: transparent !important;
  resize: none;
}
ui-form .textarea-wrapper p, ui-form .textarea-wrapper textarea {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 26px;
  letter-spacing: -0.2px;
  color: #050505;
  margin: 0;
}
ui-form:not(.sh-form-disabled):hover label {
  color: #4d4d4d;
}
ui-form.ng-dirty.ng-invalid .textarea-wrapper,
ui-form.ng-dirty.ng-invalid .form-input-prefix, ui-form.ng-touched.ng-invalid .textarea-wrapper,
ui-form.ng-touched.ng-invalid .form-input-prefix {
  border-color: #fa6857;
  background-color: #feeeec;
  padding: 12px 0 12px 14px;
}
ui-form.ng-dirty.ng-invalid .form-input-prefix + .form-input, ui-form.ng-touched.ng-invalid .form-input-prefix + .form-input {
  padding-left: 0;
}
ui-form.sh-form-disabled label {
  color: #9e9e9e;
}
ui-form.sh-form-disabled .form-input,
ui-form.sh-form-disabled .textarea-wrapper {
  background-color: #f2f2f2;
  color: #b7b7b7;
  -webkit-text-fill-color: #b7b7b7;
}
ui-form.sh-form-disabled.sh-form-readonly label {
  color: #050505;
}
ui-form.sh-form-disabled.sh-form-readonly .form-input,
ui-form.sh-form-disabled.sh-form-readonly .textarea-wrapper {
  background-color: #fff;
  color: #050505;
  border-color: #ededed;
  -webkit-text-fill-color: #050505;
}
ui-form.sh-form-active label {
  color: #050505;
}
ui-form.sh-form-active .form-input,
ui-form.sh-form-active .textarea-wrapper {
  background-color: #ededed;
}
ui-form.sh-form-readonly .form-input {
  background-color: #f8f8f8;
  color: #767676;
  -webkit-text-fill-color: #767676;
}
ui-form.sh-form-checkbox {
  margin-bottom: 12px;
}

.form-text.text-muted {
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.1px;
  color: #767676 !important;
  text-indent: 0;
  margin-top: 4px;
}
.form-text.text-muted .important {
  color: #ff6933;
}

.dropdown-menu {
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 4px;
  transform: translateY(-3px) scale(0.98);
  transition: all 0.25s ease;
  opacity: 0;
  visibility: hidden;
  display: block !important;
  outline: none !important;
}
.dropdown-menu.scrollable {
  max-height: 390px;
  overflow-y: scroll;
  overflow-x: auto;
}
.dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0) scale(1);
}
.dropdown-menu:not(.dropdown-submenu):not(.dropdown-menu-inverted) {
  right: 0 !important;
  left: auto !important;
  margin-top: 4px;
  top: 100%;
}
.dropdown-menu.dropdown-submenu {
  left: 100% !important;
  top: 0 !important;
  margin: 0 0 0 8px;
  transform: translateX(-5px);
}
.dropdown-menu.dropdown-submenu.show {
  transform: translateX(0);
}
.dropdown-menu-inverted {
  margin-top: 4px;
  top: 100%;
}
.dropdown-menu.dropdown-top-left {
  bottom: 100% !important;
  top: auto !important;
  right: auto !important;
  left: auto !important;
  margin: 0 0 -2px 6px;
  transform: translateY(3px) translateX(-3px) scale(0.98);
}
.dropdown-menu.dropdown-top-left.show {
  transform: translateY(0) translateX(0) scale(1);
}
.dropdown-menu .label {
  padding: 6px 8px;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: normal;
  color: #767676;
  margin-top: 7px;
}
.dropdown-menu li a {
  border-radius: 6px;
  background: #ffffff;
  padding: 8px 12px;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 25px;
  letter-spacing: -0.2px;
  cursor: pointer;
  color: #050505;
  display: flex;
  align-items: center;
  transition: background 0.25s ease, color 0.25s ease;
  height: 40px;
}
.dropdown-menu li a svg-icon {
  margin-left: auto;
  margin-right: -4px;
  padding-left: 16px;
}
.dropdown-menu li a svg-icon:first-child {
  padding-left: 0;
}
.dropdown-menu li a svg-icon svg {
  display: block;
  width: 16px;
  height: 16px;
}
.dropdown-menu li a svg-icon.icon {
  margin: 0 12px 0 0;
  display: block;
  width: 16px;
  height: 16px;
}
.dropdown-menu li a svg-icon.warning svg path {
  fill: #ffad05;
}
.dropdown-menu li a::before {
  display: none;
}
.dropdown-menu li a.logout {
  color: rgba(0, 0, 0, 0.5);
}
.dropdown-menu li a.danger {
  color: #f23918;
}
.dropdown-menu li a.danger svg-icon svg path {
  fill: #f23918;
}
.dropdown-menu li a.success {
  color: #07c971;
}
.dropdown-menu li a.success svg-icon svg path {
  fill: #07c971;
}
.dropdown-menu li a:hover {
  background: #f2f2f2 !important;
}
.dropdown-menu li a:active {
  background: #ededed !important;
  color: #050505;
}
.dropdown-menu li .search-input {
  border-radius: 6px;
  box-shadow: 0 1px 0 0 #f8f8f8;
  border: solid 1px #ededed;
  background-color: #ffffff;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 38px;
  letter-spacing: -0.2px;
  color: #050505;
  width: 100%;
  padding: 0 12px;
  height: 38px;
  appearance: none;
  outline: none;
}
.dropdown-menu li .search-input:focus {
  outline: none;
}
.dropdown-menu li .search-input::-webkit-input-placeholder {
  color: #9e9e9e;
}
.dropdown-menu li .search-input:-moz-placeholder {
  color: #9e9e9e;
}
.dropdown-menu li .search-input::-moz-placeholder {
  color: #9e9e9e;
}
.dropdown-menu li .search-input:-ms-input-placeholder {
  color: #9e9e9e;
}

tagify .tagify {
  padding: 4px 8px;
  min-height: 48px;
  height: auto;
}
tagify .tagify__input {
  margin: 2px 2px 2px 0;
  cursor: text;
  height: 32px;
}
tagify .tagify__input::before {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #9e9e9e !important;
  transition: none !important;
  opacity: 1;
}
tagify .tagify__tag {
  background-color: #fff;
  border: solid 1px #e5e5e5;
  height: 32px;
  border-radius: 16px;
  margin: 2px 0 2px 2px;
}
tagify .tagify__tag > div {
  padding: 8px 8px 8px 12px;
  font-size: 11px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  color: #050505;
  border-right: 1px solid #f2f2f2;
  border-radius: 0;
  opacity: 1 !important;
}
tagify .tagify__tag > div::before {
  display: none;
}
tagify .tagify__tag x {
  width: 30px;
  height: 100%;
  padding: 0;
  display: block;
  top: auto !important;
  transition: transform 0.3s ease;
  transform-origin: center;
  will-change: transform;
  background-color: #767676;
  background-size: cover;
  -webkit-mask-image: url("/assets/icons/icons-close-small.svg");
  mask-image: url("/assets/icons/icons-close-small.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  mask-size: 12px 12px;
  cursor: pointer;
}
tagify .tagify__tag x:hover {
  background-color: #050505;
}
tagify .tagify__tag x::after {
  display: none;
}