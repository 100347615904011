@import "variables";

* {
  font-family: $font-family-base;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.text-danger {
  color: $red-500 !important;
}

html {
  height: 100%;
  font-size: $base-font-size;
  scroll-behavior: smooth;
}

iframe {
  border: 0;
}
