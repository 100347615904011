ngx-skeleton-loader {
  display: inline-flex;

  .skeleton-loader {
    background-color: $gray-250 !important;
    vertical-align: top !important;
  }

  .loader {
    background-color: $gray-250 !important;
    margin-bottom: 0 !important;

    &::before {
      background-image: linear-gradient(135deg, $gray-250 34.9%, $gray-100 61.98%, $gray-250 89.06%);
    }
  }
}
