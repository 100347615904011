@import 'variables';

context-menu-content .ngx-contextmenu {
  ul.ngx-contextmenu--dropdown-menu {
    & > li > [role='menuitem'] {
      font-family: 'Manrope', sans-serif;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 25px;
      letter-spacing: -0.2px;
      cursor: pointer;
      border-radius: 6px;
      transition: background 0.25s ease, color 0.25s ease;
      align-items: center;

      svg-icon {
        margin-left: auto;
        margin-right: -4px;
        padding-left: 16px;

        &:first-child {
          padding-left: 0;
        }

        svg {
          display: block;
          width: 16px;
          height: 16px;
        }

        &.icon {
          margin: 0 12px 0 0;
          display: block;
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &.submenu {
    ul.ngx-contextmenu--dropdown-menu {
      margin-left: 8px;

      & > li > [role='menuitem'] {
        span {
          margin-right: auto;
        }
      }
    }
  }
}

.ngx-contextmenu {
  --ngx-contextmenu-focusable-border-bottom: none;
  --ngx-contextmenu-font-family: sans-serif;
  --ngx-contextmenu-background-color: white;
  --ngx-contextmenu-border-radius: 8px;
  --ngx-contextmenu-border: solid 1px rgba(0, 0, 0, 0.1);
  --ngx-contextmenu-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  --ngx-contextmenu-font-size: 14px;
  --ngx-contextmenu-margin: 4px 0 0;
  --ngx-contextmenu-min-width: 160px;
  --ngx-contextmenu-outline: none;
  --ngx-contextmenu-padding: 4px;
  --ngx-contextmenu-text-color: #050505;
  --ngx-contextmenu-text-disabled-color: #9e9e9e;

  /* Styling of context menu items */
  --ngx-contextmenu-item-arrow-left: '';
  --ngx-contextmenu-item-arrow-right: '';
  --ngx-contextmenu-item-background-hover-color: #f2f2f2;
  --ngx-contextmenu-item-separator-color: #9e9e9e;
  --ngx-contextmenu-item-separator-padding: 8px;
  --ngx-contextmenu-item-separator-width: 100%;
  --ngx-contextmenu-item-padding: 8px 12px;
  --ngx-contextmenu-item-text-hover-color: #050505;
}
