@import '../variables';

.dropdown-menu {
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 4px;
  transform: translateY(-3px) scale(0.98);
  transition: all 0.25s ease;
  opacity: 0;
  visibility: hidden;
  display: block !important;
  outline: none !important;

  &.scrollable {
    max-height: 390px;
    overflow-y: scroll;
    overflow-x: auto;
  }

  &.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0) scale(1);
  }

  &:not(.dropdown-submenu):not(.dropdown-menu-inverted) {
    right: 0 !important;
    left: auto !important;
    margin-top: 4px;
    top: 100%;
  }

  &.dropdown-submenu {
    left: 100% !important;
    top: 0 !important;
    margin: 0 0 0 8px;
    transform: translateX(-5px);

    &.show {
      transform: translateX(0);
    }
  }

  &-inverted {
    margin-top: 4px;
    top: 100%;
  }

  &.dropdown-top-left {
    bottom: 100% !important;
    top: auto !important;
    right: auto !important;
    left: auto !important;
    margin: 0 0 -2px 6px;
    transform: translateY(3px) translateX(-3px) scale(0.98);

    &.show {
      transform: translateY(0) translateX(0) scale(1);
    }
  }

  .label {
    padding: 6px 8px;
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    color: $gray-700;
    margin-top: 7px;
  }

  li {
    a {
      border-radius: 6px;
      background: #ffffff;
      padding: 8px 12px;
      font-family: 'Manrope', sans-serif;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 25px;
      letter-spacing: -0.2px;
      cursor: pointer;
      color: $gray-900;
      display: flex;
      align-items: center;
      transition: background .25s ease, color .25s ease;
      height: 40px;

      svg-icon {
        margin-left: auto;
        margin-right: -4px;
        padding-left: 16px;

        &:first-child {
          padding-left: 0;
        }

        svg {
          display: block;
          width: 16px;
          height: 16px;
        }

        &.icon {
          margin: 0 12px 0 0;
          display: block;
          width: 16px;
          height: 16px;
        }

        &.warning {
          svg {
            path {
              fill: $yellow-500;
            }
          }
        }
      }

      &::before {
        display: none;
      }

      &.logout {
        color: rgba($black, 50%);
      }

      &.danger {
        color: $red-500;

        svg-icon {
          svg {
            path {
              fill: $red-500;
            }
          }
        }
      }

      &.success {
        color: $green-500;

        svg-icon {
          svg {
            path {
              fill: $green-500;
            }
          }
        }
      }

      &:hover {
        background: $gray-200 !important;
      }

      &:active {
        background: $gray-250 !important;
        color: $gray-900;
      }
    }

    .search-input {
      border-radius: 6px;
      box-shadow: 0 1px 0 0 $gray-100;
      border: solid 1px $gray-250;
      background-color: $white;
      font-family: 'Manrope', sans-serif;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 38px;
      letter-spacing: -0.2px;
      color: $gray-900;
      width: 100%;
      padding: 0 12px;
      height: 38px;
      appearance: none;
      outline: none;

      &:focus {
        outline: none;
      }

      @include placeholder {
        color: $gray-600;
      }
    }
  }
}
