@import 'variables';

.bs-datepicker.theme-sh {
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px $gray-300;
  background-color: #fff;
  display: flex;
  flex-flow: column-reverse;
  margin-top: 8px;
  animation: showUp 0.25s ease;

  .bs-datepicker-container {
    padding: 0;
  }

  .bs-datepicker-head {
    text-align: left;
    min-width: 100%;
    width: 100%;
    height: auto;
    padding: 0 10px;
    margin: 13px 0 -1px;
    border-radius: 0;

    bs-datepicker-navigation-view {
      display: flex;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 25%;
        right: 25%;
        height: 100%;
      }
    }

    button {
      font-size: 16px;
      font-weight: 600;
      color: $gray-900;
      padding: 0 2px;
      position: relative;
      border-radius: 6px;
      transition: background-color 0.3s ease;

      &.next {
        margin-left: auto;
      }

      &.previous {
        margin-right: auto;
        transform: rotate(180deg);
      }

      &.next,
      &.previous {
        height: 29px;
        line-height: 29px;
        background: url('^assets/icons/icons-arrow-right.svg') no-repeat center center;
        background-size: 11px;

        span {
          display: none;
        }

        &:hover {
          background-color: $gray-200;
        }
      }
    }
  }

  .bs-datepicker-body {
    padding: 0 11px 11px 11px;
    border-radius: 0;
    min-width: 332px;
    min-height: 100px;
    border: none;

    table {
      thead {
        tr {
          th {
            font-size: 12px;
            font-weight: 500;
            color: $gray-700;
            padding-bottom: 6px;
          }
        }
      }

      tbody {
        tr {
          td {
            span {
              width: 35px;
              height: 31px;
              line-height: 31px;
              border-radius: 6px;
              font-size: 14px;
              font-weight: 500;
              text-align: center;
              color: #050505;
              padding: 0 !important;
              margin: 4px 6px;
              transition: all 0.3s ease;

              &.is-other-month {
                display: none;
              }

              &:not(.selected).is-highlighted {
                background-color: $gray-200;
              }

              &.today-picker-cell {
                background-color: transparent;
                color: var(--primary, $blue-500);
              }

              &.selected {
                background-color: var(--primary, $blue-500) !important;
                color: #fff;
              }

              &.disabled {
                color: $gray-500 !important;
              }

              &.select-start {
                z-index: inherit;

                &:before {
                  background-color: $gray-200 !important;
                  top: -2px;
                  bottom: -2px;
                  left: -2px;
                  right: -6px;
                  border-top-left-radius: 6px;
                  border-bottom-left-radius: 6px;
                }
              }

              &.select-end {
                &:before {
                  right: -2px !important;
                  border-top-right-radius: 6px;
                  border-bottom-right-radius: 6px;
                }
              }

              &.in-range {
                &:not(.select-start):before {
                  background-color: $gray-200 !important;
                  top: -2px;
                  bottom: -2px;
                  left: -6px;
                  right: -6px;
                }
              }
            }
          }

          &.in-range {
            &:last-child {
              background: blue !important;

              &:before {
                background: blue !important;
              }
            }
          }
        }
      }
    }
  }
}
