@import 'variables';

.tippy-box {
  &[data-theme='light'] {
    border-radius: 10px;
    background-color: #fcfcfc;
    box-shadow: 0 16px 80px 0 rgba(0, 0, 0, 0.12);

    .tippy-content {
      padding: 12px 12px 16px 16px;
    }

    &[data-placement^='top'] > .tippy-arrow {
      width: 8px;
      height: 5px;
      color: #fcfcfc;

      &:before {
        border-width: 5px 4px 0;
        bottom: -5px;
      }
    }

    &[data-placement^='bottom'] > .tippy-arrow {
      width: 8px;
      height: 5px;
      color: #fcfcfc;

      &:before {
        border-width: 0 5px 4px;
        top: -5px;
      }
    }
  }

  &:not([data-theme='light'])[data-theme='dropdown'] {
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    margin-bottom: -6px;
    border-radius: 12px;
    border: none;
    outline: solid 1px rgba(0, 0, 0, 0.04);

    .tippy-content {
      padding: 20px;
      text-align: left;
      white-space: inherit;
    }

    .tippy-arrow {
      display: none !important;
    }
  }

  &:not([data-theme='light']):not([data-theme='dropdown']):not([data-theme='tip']) {
    background-color: #000;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);

    .tippy-content {
      font-family: 'Manrope', sans-serif;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      padding: 4px 8px;
      white-space: pre-line;
      text-align: center;
    }

    .tippy-arrow {
      width: 9px;
      height: 4px;
      background: #000;

      &:before {
        width: 10px;
        height: 4px;
        background: url('/assets/tippy-triangle.svg') no-repeat 0 0;
        border: none !important;
        transform-origin: center center !important;
        background-size: 10px 4px;
      }
    }

    &[data-placement^='top'] > .tippy-arrow {
      &:before {
        bottom: -4px !important;
        margin-left: -1px;
      }
    }

    &[data-placement^='bottom'] > .tippy-arrow {
      &:before {
        top: -4px !important;
        transform: rotate(180deg);
        margin-left: -1px;
      }
    }

    &[data-placement^='left'] > .tippy-arrow {
      &:before {
        transform: rotate(-90deg);
        right: -6px;
      }
    }

    &[data-placement^='right'] > .tippy-arrow {
      &:before {
        transform: rotate(90deg);
        left: -6px;
      }
    }
  }

  &:not([data-theme='light']):not([data-theme='dropdown'])[data-theme='tip'] {
    border-radius: 12px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
    outline: solid 1px rgba(0, 0, 0, 0.02);
    border: none;
    background: #fff;

    &[data-placement^='left'],
    &[data-placement^='bottom-end'] {
      border-top-right-radius: 0 !important;
    }

    &[data-placement^='bottom-start'] {
      border-top-left-radius: 0 !important;
    }

    &[data-placement='right-start'] {
      margin-left: -26px;
      margin-top: 20px;
      border-top-left-radius: 0 !important;
    }

    &[data-placement='right-end'] {
      margin-left: -26px;
      margin-bottom: 20px;
      border-bottom-left-radius: 0 !important;
      text-align: left;
    }

    &[data-placement='left-end'] {
      border-top-right-radius: 20px !important;
      border-bottom-right-radius: 0 !important;
    }

    .tippy-content {
      padding: 16px 20px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 22px;
      letter-spacing: -0.1px;
      color: $gray-900;

      p {
        &:last-child {
          margin: 0;
        }
      }
    }
  }

  &:not([data-theme='light']):not([data-theme='dropdown'])[data-theme='qr'] {
    border-radius: 6px;
    background-color: $gray-100;
    border: none;

    .tippy-content {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 20px;
      letter-spacing: -0.1px;
      text-align: center;
      color: $gray-700;
    }
  }
}

.tippy-channel {
  text-transform: capitalize;

  &-sms {
    text-transform: uppercase;
  }
}

.tippy-box[data-animation='scale'][data-state='hidden'] {
  opacity: 0;
}

.tippy-box[data-animation='scale'][data-state='visible'] {
  opacity: 1;
}

.tippy-top.tippy-box[data-animation='scale'],
.tippy-box[data-animation='scale'][data-placement^='top'] {
  opacity: 0;
  transform: translateY(3px) scale(0.99);
}

.tippy-rop.tippy-box[data-animation='scale'][data-state='visible'],
.tippy-box[data-animation='scale'][data-state='visible'][data-placement^='top'] {
  opacity: 1;
  transform: translateY(0) scale(1);
}

.tippy-bottom.tippy-box[data-animation='scale'][data-state='hidden'],
.tippy-box[data-animation='scale'][data-state='hidden'][data-placement^='bottom'] {
  opacity: 0;
  transform: translateY(-3px) scale(0.99);
}

.tippy-bottom.tippy-box[data-animation='scale'][data-state='visible'],
.tippy-box[data-animation='scale'][data-state='visible'][data-placement^='bottom'] {
  opacity: 1;
  transform: translateY(0) scale(1);
}

.tippy-left.tippy-box[data-animation='scale'],
.tippy-box[data-animation='scale'][data-placement^='left'] {
  opacity: 0;
  transform: translateX(3px) scale(1);
}

.tippy-left.tippy-box[data-animation='scale'][data-state='visible'],
.tippy-box[data-animation='scale'][data-state='visible'][data-placement^='left'] {
  opacity: 1;
  transform: translateX(0) scale(1);
}

.tippy-right.tippy-box[data-animation='scale'],
.tippy-box[data-animation='scale'][data-placement^='right'] {
  opacity: 0;
  transform: translateX(-3px) scale(0.99);
}

.tippy-right.tippy-box[data-animation='scale'][data-state='visible'],
.tippy-box[data-animation='scale'][data-state='visible'][data-placement^='right'] {
  opacity: 1;
  transform: translateX(0) scale(1);
}

.tippy-bottom-start.tippy-box[data-animation='scale'][data-state='hidden'],
.tippy-box[data-animation='scale'][data-state='hidden'][data-placement='bottom-start'] {
  opacity: 0;
  transform: translateY(-3px) translateX(-3px) scale(0.99);
}

.tippy-bottom.tippy-box[data-animation='scale'][data-state='visible'],
.tippy-box[data-animation='scale'][data-state='visible'][data-placement='bottom-start'] {
  opacity: 1;
  transform: translateY(0) translateX(0) scale(1);
}
