@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope-ExtraBold.woff2") format("woff2"),
  url("../assets/fonts/Manrope-ExtraBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope-Bold.woff2") format("woff2"),
  url("../assets/fonts/Manrope-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope-SemiBold.woff2") format("woff2"),
  url("../assets/fonts/Manrope-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope-Medium.woff2") format("woff2"),
  url("../assets/fonts/Manrope-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope-Regular.woff2") format("woff2"),
  url("../assets/fonts/Manrope-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope-Light.woff2") format("woff2"),
  url("../assets/fonts/Manrope-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope-ExtraLight.woff2") format("woff2"),
  url("../assets/fonts/Manrope-ExtraLight.ttf") format("truetype");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "BebasNeue";
  src: url("../assets/fonts/BebasNeue-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
