@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}

@keyframes wave-minimal {
  50% {
    transform: scale(0.98);
  }
}

@keyframes typing {
  0%, 60%, 100% {
    transform: initial;
  }
  30% {
    transform: translateY(-2px);
    color: $gray-800;
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(-3px) scale(0.98);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes showUp {
  from {
    opacity: 0;
    transform: translateY(-4px) scale(0.98);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
