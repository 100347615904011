@use 'sass:math';

@mixin placeholder() {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin input-autocomplete($bg: $white, $color: $primary) {
  &:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 30px $bg;
    -webkit-text-fill-color: $color;
  }
}

@mixin role-type($color) {
  color: $color !important;
  &.icon {
    border-color: $color !important;
  }
  &::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: 1px;
    background-color: $color !important;
    margin-right: 8px;
  }
}

@mixin blackHover($opacity: 4%, $radius: 12px) {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(#000, $opacity);
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
    border-radius: $radius;
  }

  &:hover {
    &:after {
      opacity: 1;
      visibility: visible;
    }
  }
}
