@import "../variables";
@import "../mixins";

ui-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &.counter {
    position: relative;
  }

  & > label,
  label.label { // for sh-checkin-form
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: $gray-900;
    margin-bottom: 8px;
    text-indent: 0;
    transition: color 0.3s ease;
    display: block;

    em {
      letter-spacing: -0.2px;
      color: $red-500;
      margin-left: 2px;
    }

    span {
      float: right;
      color: $gray-600;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.1px;
    }
  }

  .count {
    position: absolute;
    top: 5px;
    right: 0;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: $gray-800;
  }

  ui-error-message {
    order: 2;
  }

  .form-text {
    order: 1;
  }

  .form-input,
  .textarea-wrapper {
    height: 48px;
    border-radius: 12px;
    background-color: $gray-200;
    padding: 12px 14px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: $gray-900;
    transition: background 0.3s ease, border 0.3s ease;
    width: 100%;
    border: solid 2px transparent;

    &.ng-dirty.ng-invalid,
    &.ng-touched.ng-invalid, {
      border-color: $red-400;
      background-color: $red-50;
      padding: 12px 14px;
    }

    &:focus,
    &:focus-visible {
      outline: none !important;
    }

    @include placeholder {
      color: $gray-600;
    }

    &:disabled {
      color: $gray-700;
      opacity: 1;
    }

    &:read-only {
      cursor: default;
    }

    &[type="number"] {
      -moz-appearance: textfield;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &.input-hint {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    &.form-input-prefix {
      width: auto;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding-right: 0;
      padding-top: 13px;
      display: flex;
      align-items: center;
      border-right: 0;

      & + input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding-left: 0;
        border-left: 0;

        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 1000px $gray-200 inset;
        }
      }
    }
  }

  .textarea-wrapper {
    height: auto;

    textarea {
      border-radius: 0;
      min-height: 97px;
      padding: 0 !important;
      background-color: transparent !important;
      border-color: transparent !important;
      resize: none;
    }

    p, textarea {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 26px;
      letter-spacing: -0.2px;
      color: $gray-900;
      margin: 0;
    }
  }

  &:not(.sh-form-disabled):hover {
    label {
      color: $gray-800;
    }
  }

  &.ng-dirty.ng-invalid,
  &.ng-touched.ng-invalid {
    .textarea-wrapper,
    .form-input-prefix {
      border-color: $red-400;
      background-color: $red-50;
      padding: 12px 0 12px 14px;
    }

    .form-input-prefix + .form-input {
      padding-left: 0;
    }
  }

  &.sh-form-disabled {
    label {
      color: $gray-600;
    }

    .form-input,
    .textarea-wrapper {
      background-color: $gray-200;
      color: $gray-500;
      -webkit-text-fill-color: $gray-500;
    }
  }

  &.sh-form-disabled.sh-form-readonly {
    label {
      color: $gray-900;
    }

    .form-input,
    .textarea-wrapper {
      background-color: #fff;
      color: $gray-900;
      border-color: $gray-250;
      -webkit-text-fill-color: $gray-900;
    }
  }

  &.sh-form-active {
    label {
      color: $gray-900;
    }

    .form-input,
    .textarea-wrapper {
      background-color: $gray-250;
    }
  }

  &.sh-form-readonly {
    .form-input {
      background-color: $gray-100;
      color: $gray-700;
      -webkit-text-fill-color: $gray-700;
    }
  }

  &.sh-form-checkbox {
    margin-bottom: 12px;
  }
}

.form-text.text-muted {
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.1px;
  color: $gray-700 !important;
  text-indent: 0;
  margin-top: 4px;

  .important {
    color: $orange-500;
  }
}
