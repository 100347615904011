@import 'variables';

.popover {
  position: absolute;
  z-index: 999;
  display: block;
  max-width: 340px;
  width: 100%;
  padding: 20px 24px 24px 24px;
  border-radius: 10px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--primaryLight, $blue-300);

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: -0.1px;
  color: var(--primaryDark, $blue-900);

  &-arrow {
    margin: 0 !important;
    //left: auto !important;
    //right: 8px;
    position: absolute;

    width: 16px;
    height: 8px;

    &::before {
      content: '';
      display: block;
      width: 16px;
      height: 8px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='8' viewBox='0 0 16 8'%3E%3Cpath fill='%2375E2FA' fill-rule='evenodd' d='M324.414214 1.41421356 L331 8 L331 8 L315 8 L321.585786 1.41421356 C322.366835 0.633164979 323.633165 0.633164979 324.414214 1.41421356 Z' transform='translate(-315)'/%3E%3C/svg%3E") no-repeat 0 0;
      transform-origin: center center !important;
      background-size: 16px 8px;
    }
  }

  &.bottom {
    margin-top: 8px;

    .popover-arrow {
      top: -8px;
    }
  }

  &.top {
    margin-bottom: 8px;

    .popover-arrow {
      bottom: -8px;

      &::before {
        transform: rotate(180deg);
      }
    }
  }

  &.menu {
    z-index: 9999999;
    margin-left: 4px;

    .popover-arrow {
      left: -12px !important;
      right: auto !important;
      top: 18px !important;

      &::before {
        transform: rotate(-90deg);
      }
    }
  }

  &.right {
    margin-left: 8px;
  }

  &-title {
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: $gray-900;
    margin: 0 0 8px 0;
  }

  &-artwork {
    margin: -16px -20px 20px -20px;
    display: block;
    max-width: calc(100% + 40px);
    height: auto;
    border-radius: 8px;
    overflow: hidden;
  }

  &-footer {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }
}
