@import 'mixins';

$top-panel-height: 72px;
$sidebar-collapse-breakpoint: 1100px;
$sidebar-width: 242px;
$sidebar-width-collapsed: 76px;
$sidebar-width-mobile: 271px;
$sidebar-collapse-translate-x: -5px;
$sidebar-collapse-transition: all 0.3s ease;
$mobile-breakpoint: 768px;

$white: #ffffff;
$gray-50: #fafafa;
$gray-100: #f8f8f8;
$gray-150: #F5F5F5;
$gray-200: #f2f2f2;
$gray-250: #ededed;
$gray-300: #e5e5e5;
$gray-350: #e0e0e0;
$gray-400: #cacaca;
$gray-500: #b7b7b7;
$gray-600: #9e9e9e;
$gray-700: #767676;
$gray-800: #4d4d4d;
$gray-900: #050505;
$black: #000000;

$purple-50: #f1eefb;
$purple-100: #e3ddf8;
$purple-200: #d5cdf4;
$purple-300: #c7bcf1;
$purple-400: #9883e5;
$purple-500: #8168df;
$purple-600: #5835d4;
$purple-700: #4024a8;
$purple-800: #341d87;
$purple-900: #271565;
$purple-950: #1a0e43;

$royalblue-50: #ebf2fe;
$royalblue-100: #d8e6fd;
$royalblue-200: #c4d9fd;
$royalblue-300: #9dbffb;
$royalblue-400: #6299f8;
$royalblue-500: #2874f6;
$royalblue-600: #0a5deb;
$royalblue-700: #063a89;
$royalblue-800: #042362;
$royalblue-900: #031b44;
$royalblue-950: #021027;

$blue-50: #ebfbfe;
$blue-100: #d8f7fe;
$blue-200: #b0eefc;
$blue-300: #75e2fa;
$blue-400: #4ed9f9;
$blue-500: #18cff7;
$blue-600: #09c2ec;
$blue-700: #00a6cc;
$blue-800: #045466;
$blue-900: #02313b;
$blue-950: #012027;

$green-50: #d9fbec;
$green-100: #bcf8df;
$green-200: #8cf4c7;
$green-300: #53eba8;
$green-400: #0adb7c;
$green-500: #07c971;
$green-600: #07a05a;
$green-700: #057a45;
$green-800: #045a33;
$green-900: #034628;
$green-950: #022717;

$yellow-50: #fff8eb;
$yellow-100: #fcefd4;
$yellow-200: #ffe4ad;
$yellow-300: #ffd685;
$yellow-400: #ffc247;
$yellow-500: #ffad05;
$yellow-600: #e09600;
$yellow-700: #b87a00;
$yellow-800: #7a5200;
$yellow-900: #3d2900;
$yellow-950: #291b00;

$orange-50: #fff0eb;
$orange-100: #ffe1d6;
$orange-200: #ffc3ad;
$orange-300: #ffa787;
$orange-400: #fc865b;
$orange-500: #ff6933;
$orange-600: #C64F24;
$orange-700: #a5421e;
$orange-800: #7e3014;
$orange-900: #5c2612;
$orange-950: #3d1202;

$red-50: #feeeec;
$red-100: #FDDCD8;
$red-200: #ffc3bd;
$red-300: #f9958a;
$red-400: #fa6857;
$red-500: #f23918;
$red-600: #d6290a;
$red-700: #b82309;
$red-800: #881a06;
$red-900: #611305;
$red-950: #3a0b03;

$avatar-1: #b57f50;
$avatar-2: #7a6263;
$avatar-3: #558b6e;
$avatar-4: #a0ac86;
$avatar-5: #427bc6;
$avatar-6: #9381ff;
$avatar-7: #f17384;
$avatar-8: #eb9486;
$avatar-9: #c695a2;
$avatar-10: #f4a673;
$avatar-11: #fac05e;
$avatar-12: #ed6a5a;
$avatar-13: #c3458d;
$avatar-14: #6e2594;
$avatar-15: #6320ee;

$font-family-base: 'Manrope', sans-serif;
$base-font-weight: 400;
$base-font-size: 14px;
$base-animation-time: 300ms;
$base-animation: ease;
$default-border-radius: 4px;
$base-animation: 0.3s ease;
$cubic-transition: cubic-bezier(.25, .8, .25, 1);
$border-color: $gray-400;

$body-bg: $gray-200;
$body-color: $gray-900;
$border-color: $gray-200;

$danger: $red-500 !important;
