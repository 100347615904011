@import 'variables';

$input-radius: 10px;

sh-form {
  display: block;
  position: relative;
}

.today-picker-cell {
  background-color: var(--primary, $blue-500);
}

.error-message {
  color: $red-500;
}

//.form-text.text-muted {
//  margin: 6px 0 0 0;
//  font-size: 12px;
//  color: $gray-600;
//  display: block;
//  line-height: 14px;
//  text-indent: 1px;
//  letter-spacing: 0.1px;
//}

.sh-form,
.app-form {
  position: relative;

  .error-message {
    margin: 5px 0 10px;
  }

  input[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  label {
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: $gray-900;
    margin-bottom: 8px;
    text-indent: 0;
    transition: color 0.3s ease;

    small {
      font-size: 12px;
      color: $gray-500;
      margin-left: 4px;
    }

    em {
      font-style: normal;
      color: #ff5e5b;
      margin-left: 4px;
    }
  }

  &-info {
    margin-bottom: 22px;

    p {
      font-size: 16px;
      color: rgba($black, 80%);
      margin: 0;
      letter-spacing: 0.1px;
    }
  }
}

input[type='search']::-ms-clear,
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.form-input,
.ng2-tag-input,
ng-select.ng-select-old .ng-select-container {
  @include input-autocomplete($gray-200, $gray-900);
  width: 100%;
  padding: 12px 15px 11px;
  border-radius: 12px;
  font-size: 17px;
  border: 0;
  font-weight: 400;
  font-family: $font-family-base;
  background: $gray-200;
  line-height: 26px;
  -webkit-appearance: none;
  outline: none !important;

  @include placeholder {
    color: $gray-600 !important;
    line-height: 26px;
  }

  &:disabled {
    color: $gray-700 !important;
    opacity: 1;
  }

  &:read-only {
    cursor: default;
  }
}

textarea {
  padding: 12px 15px !important;
  line-height: 24px;
  min-height: 295px;

  &.small {
    min-height: 55px;
  }
}

.input-group {
  &-text {
    border-radius: 10px;
    border: solid 1px #f4f4f4;
    background: #f4f4f4;
    border-right: 0;
    font-size: 18px;
    color: $gray-900;
    padding: 0 0 0 14px;
  }

  .form-control {
    border-left: 0;
    padding-left: 6px;
  }
}

.clear-button,
.ng-clear-wrapper .ng-clear {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: $gray-100;
  color: white;
  font-family: $font-family-base;
  font-size: 27px !important;
  font-weight: 500;
  line-height: 23px !important;
  text-align: center;
}

.ng-select.ng-select-old {
  margin-bottom: 7px;

  .ng-select-container {
    height: 47px !important;
    background: $gray-200 !important;
    border: 0px !important;
    outline: none !important;
    font-size: 18px;
    box-shadow: none !important;
    text-transform: capitalize;

    &:hover {
      box-shadow: none;
    }

    .ng-value-container {
      padding: 0;

      .ng-input {
        padding: 8px 14px !important;
        font-size: 18px;
        cursor: pointer;

        & > input {
          cursor: pointer;
        }
      }
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      height: auto !important;
      padding-left: 7px;
      padding-top: 7px;
      padding-bottom: 0;
      min-height: 47px;

      .ng-value-container {
        padding: 0;

        .ng-placeholder {
          position: relative !important;
          padding: 0;
          font-size: 18px;
          color: $gray-600;
          top: -2px;
          left: 8px;
        }

        .ng-input {
          display: none;
        }

        .ng-value {
          background: #ffffff;
          max-width: 215px;
          overflow: hidden;
          display: flex;
          border-radius: 6px;
          margin: 0 7px 7px 0;

          .ng-value-icon {
            order: 1;
            border: none !important;
            border-left: 1px solid $gray-200 !important;
            padding: 0 10px;
            margin-left: 1px;
            width: 30px;
            line-height: 30px;
            background: #ffffff !important;
            color: transparent;
            font-size: 9px;
            transition: all 0.3s ease;
            //@extend .sh-icon;
            //@extend .icon-close1;
            &:before {
              color: $gray-900;
              top: 13px;
              position: relative;
            }

            &:hover {
              background: $gray-100 !important;
            }
          }

          .ng-value-label {
            order: 0;
            font-size: 16px;
            font-weight: 500;
            color: $gray-900;
            padding: 9px 8px;
            line-height: 15px;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;

            &:before {
              vertical-align: 1px;
            }
          }
        }
      }
    }

    .ng-arrow-wrapper {
      top: -3px;
    }

    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        .ng-option {
          &.ng-option-selected {
            display: none !important;
          }
        }
      }
    }
  }

  &.ng-select-searchable.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        .ng-placeholder {
          margin-right: 5px;
          top: -3px;
        }

        .ng-input {
          display: block !important;
          padding: 0 5px !important;
          position: relative;
          top: -3px;
        }
      }
    }
  }

  &.ng-select-searchable {
    .ng-select-container {
      .ng-value-container {
        .ng-input {
          top: 4px;
        }
      }
    }
  }

  .ng-arrow-wrapper {
    //@extend .sh-icon;
    //@extend .icon-arrow-down;
    border: none;
    display: block;
    padding: 0;
    transition: transform 0.25s ease;
    right: -6px;
    font-size: 16px;

    .ng-arrow {
      display: none !important;
    }
  }

  .ng-clear-wrapper {
    position: absolute !important;
    top: 20px;
    right: 35px;
    font-size: 10px;
    margin: auto;
    //@extend .sh-icon;
    //@extend .icon-close1;
    color: $gray-500;
    transition: all 0.3s ease;

    &:hover {
      color: $gray-600;
    }

    .ng-clear {
      display: none !important;
    }
  }

  &.ng-select-opened {
    & .ng-arrow-wrapper {
      transform: rotateZ(180deg);
    }
  }

  &.ng-select-disabled {
    & * {
      cursor: default !important;
    }

    .ng-arrow-wrapper {
      display: none !important;
    }
  }

  .ng-dropdown-panel {
    border-radius: 8px;
    background: #ffffff;
    padding: 8px 10px;
    margin: 8px 0 0 0;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px $gray-300;
    background: $white;

    .ng-dropdown-panel-items {
      max-height: 185px;
      overflow-x: auto;
    }

    animation: slide-up 0.4s ease;

    &.ng-select-top {
      margin: 0 0 8px 0;
    }

    .ng-dropdown-panel-items {
      .ng-option {
        border-radius: 6px;
        background: #ffffff;
        padding: 10px;
        transition: all 0.25s ease;
        margin: 4px 0;
        line-height: 15px;
        text-transform: capitalize;

        .ng-option-label,
        span {
          font-size: 16px;
          // font-family: "Archivo", "Helvetica", Poppins, sans-serif;
          font-weight: 500 !important;

          &:before {
            vertical-align: 1px;
          }
        }

        &.ng-option-disabled {
          cursor: default;
        }

        &:not(.ng-option-disabled):not(.ng-option-selected):hover {
          background: rgba($black, 5%) !important;
        }

        &.ng-option-selected,
        &.ng-option-selected.ng-option-marked {
          position: relative;
          background: rgba(#18cff7, 10%) !important;

          .ng-option-label,
          span,
          i {
            color: #18cff7;
          }
        }

        &.ng-option-marked {
          background: rgba($black, 5%) !important;
        }
      }
    }
  }
}

.dropdown-menu {
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 4px;
  transform: translateY(-3px) scale(0.98);
  transition: all 0.25s ease;
  opacity: 0;
  visibility: hidden;
  display: block !important;
  outline: none !important;

  &.scrollable {
    max-height: 390px;
    overflow-y: scroll;
    overflow-x: auto;
  }

  &.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0) translateX(0) scale(1);
  }

  &-inline {
    position: relative;
    margin: 0;
    box-shadow: none;
    border: none;
  }

  &:not(.dropdown-submenu):not(.dropdown-menu-inverted) {
    right: 0 !important;
    left: auto !important;
    margin-top: 4px;
    top: 100%;
  }

  &.dropdown-submenu {
    left: 100% !important;
    top: 0 !important;
    margin: 0 0 0 8px;
    transform: translateX(-5px);

    &.show {
      transform: translateX(0);
    }
  }

  &-inverted {
    margin-top: 6px;
  }

  &.dropdown-top-left {
    bottom: 100% !important;
    top: auto !important;
    right: auto !important;
    left: auto !important;
    margin: 0 0 6px 0;
    transform: translateY(3px) translateX(-3px) scale(0.98);

    &.show {
      transform: translateY(0) translateX(0) scale(1);
    }
  }

  li {
    a {
      border-radius: 8px;
      background: #ffffff;
      padding: 8px 12px;
      font-family: 'Manrope', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.3px;
      cursor: pointer;
      color: $gray-900;
      display: flex;
      align-items: center;
      transition: background 0.25s ease, color 0.25s ease;
      height: 40px;

      svg-icon {
        margin-left: auto;
        margin-right: -4px;
        padding-left: 16px;

        &:first-child {
          padding-left: 0;
        }

        svg {
          display: block;
          width: 16px;
          height: 16px;
        }

        &.icon {
          margin: 0 12px 0 0;
          display: block;
          width: 16px;
          height: 16px;
        }

        &.active-dot {
          position: relative;

          &::after {
            content: '';
            position: absolute;
            right: -4px;
            bottom: -4px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $green-500;
            display: block;
          }
        }
      }

      &::before {
        display: none;
      }

      &.logout {
        color: rgba($black, 50%);
      }

      &.danger {
        color: $red-500;

        svg-icon {
          svg {
            path {
              fill: $red-500;
            }
          }
        }
      }

      &.success {
        color: $green-500;

        svg-icon {
          svg {
            path {
              fill: $green-500;
            }
          }
        }
      }

      &.warning {
        color: $yellow-700;

        svg-icon {
          svg {
            path {
              fill: $yellow-700;
            }
          }
        }
      }

      &:hover {
        background: $gray-200 !important;
      }

      &:active {
        background: $gray-250 !important;
        color: $gray-900;
      }
    }

    &.filter {
      margin-bottom: 8px;
    }

    .search-input {
      border-radius: 8px;
      box-shadow: 0 1px 0 0 $gray-100;
      border: solid 1px $gray-300;
      background-color: $white;
      font-family: 'Manrope', sans-serif;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 24px;
      letter-spacing: -0.2px;
      color: $gray-900;
      width: 100%;
      padding: 8px 12px;
      height: 40px;
      appearance: none;
      outline: none;

      &:focus {
        outline: none;
      }

      @include placeholder {
        color: $gray-700;
      }
    }
  }
}

.custom-checkbox {
  position: absolute;
  opacity: 0;
  visibility: hidden;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-size: 15px !important;
    letter-spacing: normal;
    color: $gray-900;
    line-height: 21px;
    padding-left: 28px;

    &.required {
      &:after {
        content: ' *';
        color: #fc5130;
      }
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 1px;
      display: block;
      width: 18px;
      height: 18px;
      background: transparent;
      border: 1px solid $gray-400;
      border-radius: 4px;
      transition: background-color 0.3s ease, border-color 0.3s ease;
      -webkit-transition: background-color 0.3s ease, border-color 0.3s ease;
    }

    a {
      color: #18cff7;

      &:hover {
        color: #18cff7;
        text-decoration: underline;
      }
    }

    svg {
      position: absolute;
      z-index: 1;
      top: 6px;
      left: 3px;
      fill: none;
      stroke: white;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 16px;
      stroke-dashoffset: 16px;
      transition: all 0.3s ease;
      transition-delay: 0.2s;
      transform: translate3d(0, 0, 0);
    }

    &.checked {
      &:before {
        border-color: #18cff7;
        background-color: #18cff7;
        animation: wave 0.4s ease;
        -webkit-animation: wave 0.4s ease;
      }
    }
  }

  &-gray + label {
    &:before {
      border-radius: 2px;
      border: none;
      background-color: $gray-200;
    }
  }

  &-right + label {
    padding-left: 0;
    padding-right: 28px;
    text-align: right;

    &:before {
      left: auto;
      right: 0;
    }
  }

  &-right:checked + label {
    svg {
      left: auto;
      right: 3px;
    }
  }

  &:checked + label {
    &:before {
      border-color: #18cff7;
      background-color: #18cff7;
      animation: wave 0.4s ease;
      -webkit-animation: wave 0.4s ease;
    }

    svg {
      stroke-dashoffset: 0;
    }
  }
}

.custom-radio {
  position: absolute;
  opacity: 0;
  visibility: hidden;

  & + label {
    position: relative;
    cursor: pointer;
    font-size: 14px !important;
    letter-spacing: normal;
    color: $gray-800;
    line-height: 21px;
    padding: 0 0 0 28px;
    margin: 0;
    transition: color 0.3s ease;

    &:before {
      content: '';
      position: absolute;
      left: 8px;
      top: 3px;
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: solid 1px $gray-400;
      transition: background-color 0.3s ease, border-color 0.3s ease;
    }

    &:after {
      content: '';
      position: absolute;
      left: 12px;
      top: 7px;
      opacity: 0;
      visibility: hidden;
      width: 7px;
      height: 7px;
      background: #ffffff;
      border-radius: 50%;
    }

    &:hover {
      color: $gray-900;

      &:before {
        border-color: $gray-500;
      }
    }
  }

  &:checked + label {
    &:before {
      background-color: #18cff7;
      border-color: #18cff7;
      animation: wave 0.4s ease;
      -webkit-animation: wave 0.4s ease;
    }

    &:after {
      opacity: 1;
      visibility: visible;
    }
  }
}

// @TODO: replace with compoentn
.custom-switch {
  height: 23px;

  input {
    display: none;

    & + label {
      position: relative;
      height: 23px;
      width: 40px;
      margin: 0;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 40px;
        height: 23px;
        border-radius: 12px;
        background-color: $gray-300;
        transition: all 0.3s ease;
      }

      &:after {
        content: '';
        display: block;
        width: 19px;
        height: 19px;
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.15);
        background-color: #ffffff;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        left: 2px;
        transition: left 0.3s ease, background-color 0.3s ease;
      }
    }

    &:checked + label,
    &.checked + label {
      &::before {
        background-color: var(--primary, $blue-500);
      }

      &::after {
        left: 19px;
      }
    }
  }

  &-alt {
    input {
      & + label {
        height: 24px;
        width: 44px;

        &:before {
          width: 44px;
          height: 24px;
          border: solid 2px $gray-400;
          background: #ffffff;
        }

        &:after {
          display: block;
          width: 16px;
          height: 16px;
          box-shadow: none;
          background-color: $gray-800;
          top: 4px;
          left: 4px;
        }
      }

      &:checked + label,
      &.checked + label {
        &::before {
          border-color: var(--primary, $blue-500);
          background-color: #ffffff;
        }

        &::after {
          left: 24px;
          background-color: var(--primary, $blue-500);
          background-image: url('/assets/icons/icons-checkmark-white.svg');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 8px;
        }
      }

      &:not(:checked) {
        &:hover + label {
          &:before {
            border-color: $gray-500;
          }

          &:after {
            background-color: $gray-900;
          }
        }
      }
    }
  }
}
