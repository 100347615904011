tagify {
  .tagify {
    padding: 4px 8px;
    min-height: 48px;
    height: auto;

    &__input {
      margin: 2px 2px 2px 0;
      cursor: text;
      height: 32px;

      &::before {
        font-family: "Manrope", sans-serif;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: $gray-600 !important;
        transition: none !important;
        opacity: 1;
      }
    }

    &__tag {
      background-color: #fff;
      border: solid 1px $gray-300;
      height: 32px;
      border-radius: 16px;
      margin: 2px 0 2px 2px;

      & > div {
        padding: 8px 8px 8px 12px;
        font-size: 11px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 16px;
        color: $gray-900;
        border-right: 1px solid $gray-200;
        border-radius: 0;
        opacity: 1 !important;

        &::before {
          display: none;
        }
      }

      x {
        width: 30px;
        height: 100%;
        padding: 0;
        display: block;
        top: auto !important;
        transition: transform 0.3s ease;
        transform-origin: center;
        will-change: transform;
        background-color: $gray-700;
        background-size: cover;
        -webkit-mask-image: url("/assets/icons/icons-close-small.svg");
        mask-image: url("/assets/icons/icons-close-small.svg");
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: center;
        mask-position: center;
        mask-size: 12px 12px;
        cursor: pointer;

        &:hover {
          background-color: $gray-900;
        }

        &::after {
          display: none;
        }
      }
    }
  }
}
