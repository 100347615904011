@import 'variables';

.ng-select-sh {
  display: flex !important;
  margin: 0;

  .ng-select-container {
    .ng-value-container {
      overflow: initial !important;

      .ng-placeholder {
        position: relative !important;
        top: auto !important;
        padding: 0 !important;
      }

      .ng-value {
        overflow: initial !important;

        ui-request-icons {
          width: 16px;
          height: 16px;
          display: block;
          margin-right: 12px;

          svg {
            width: 16px;
            height: 16px;
            display: block;
          }
        }

        ui-flag-icon {
          margin-right: auto;

          svg {
            margin-right: 12px;
          }
        }

        .ng-value-label {
          &.type {
            &::before {
              vertical-align: 1px;
            }
          }
        }
      }
    }

    //.arrow {
    //  .arrow-icon {
    //    display: block;
    //    transition: all 0.3s ease;
    //    transform-origin: center;
    //    will-change: transform;
    //  }
    //}

    svg {
      path {
        transition: fill 0.3s ease;
      }
    }

    ui-avatar {
      margin: 0 12px 0 -6px;
    }

    .ng-arrow-wrapper {
      display: none;
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      .arrow {
        .arrow-icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  .ng-dropdown-header {
    padding: 4px;
    border: none;
    position: relative;
    z-index: 9999;
    background: #ffffff;

    .search-input {
      border-radius: 8px;
      box-shadow: 0 1px 0 0 $gray-100;
      border: solid 1px $gray-250;
      background-color: $white;
      font-family: 'Manrope', sans-serif;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 38px;
      letter-spacing: -0.2px;
      color: $gray-900;
      width: 100%;
      padding: 0 12px;
      height: 38px;
      appearance: none;
      outline: none;

      &:focus {
        outline: none;
      }

      @include placeholder {
        color: $gray-700;
      }
    }
  }

  .ng-dropdown-panel {
    padding: 0;
    margin: 4px 0 0 0;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    width: auto;
    animation: slide-up 0.4s ease;
    overflow: initial !important;

    .ng-dropdown-panel-items {
      max-height: 185px;
      overflow-x: auto;
    }

    &.ng-select-top {
      margin: 0 0 8px 0;
      top: auto;
    }

    .ng-dropdown-panel-items {
      .ng-optgroup {
        font-family: 'Manrope', sans-serif;
        font-size: 11px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 16px;
        letter-spacing: normal;
        color: $gray-700;
        padding: 0 12px;
        margin: 12px 0 4px;
      }

      .ng-option {
        margin: 0 4px 2px;
        padding: 8px 12px;
        border-radius: 8px !important;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        min-height: 40px;
        height: auto;
        line-height: 24px;
        position: relative;

        .clear {
          display: none;
        }

        ui-avatar {
          margin: 0 8px 0 -4px;
        }

        svg-icon.option-icon {
          margin-right: 12px;

          svg {
            width: 16px;
            height: 16px;
            display: block;
          }
        }

        ui-request-icons {
          width: 16px;
          height: 16px;
          display: block;
          margin-right: 12px;

          svg {
            width: 16px;
            height: 16px;
            display: block;
          }
        }

        .ng-option-icon {
          display: block;
          margin-right: 12px;

          svg {
            display: block;
            width: 12px;
            height: 12px;
          }
        }

        .ng-option-label {
          font-family: 'Manrope', sans-serif;
          font-size: 16px;
          font-weight: 500 !important;
          font-stretch: normal;
          font-style: normal;
          line-height: 24px;
          letter-spacing: -0.2px;
          color: $gray-900;

          &.type {
            &::before {
              vertical-align: 2px;
            }
          }

          &.country {
            display: flex;
            width: 100%;

            .prefix {
              margin-left: auto;
              color: $gray-600 !important;
            }

            span:not(.prefix) {
              text-overflow: ellipsis;
              overflow: hidden;
              max-width: 270px;
            }

            ui-flag-icon {
              display: flex;
              align-items: center;
              margin-right: 12px;

              span {
                width: 22px;
                height: 16px;
                line-height: 16px;
                background-size: cover;
                background-position: center center;
                border-radius: 3px;
                margin-right: 12px;
              }
            }
          }
        }

        .ng-option-checkmark {
          display: none;
          margin-left: auto;
          will-change: transform;

          svg {
            display: block;
            width: 12px;
            height: 12px;
          }
        }

        &:first-child {
          margin-top: 4px;
        }

        &:first-child:last-child {
          margin-bottom: 4px;
        }

        &.ng-option-disabled {
          cursor: default;
        }

        &:not(.ng-option-disabled):not(.ng-option-selected):hover,
        &.ng-option-marked {
          background-color: $gray-200 !important;
        }

        &.ng-option-selected,
        &.ng-option-selected.ng-option-marked {
          background-color: $gray-200 !important;

          .ng-option-checkmark {
            display: block;
          }

          .clear {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: block;
          }

          .ng-option-label {
            margin-right: 16px;
            font-weight: 600;

            &:not(.type) {
              span {
                color: $gray-900;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }

  .ng-dropdown-footer {
    border: none;
    padding: 0;
    margin-top: -2px;

    .ng-option {
      margin: 0 4px 2px;
      padding: 8px 12px;
      border-radius: 8px !important;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      height: 40px;
      line-height: 25px;
      cursor: pointer;

      .ng-option-icon {
        display: block;
        margin-right: 12px;

        svg {
          display: block;
          width: 24px;
          height: 18px;
        }
      }

      .ng-option-label {
        font-family: 'Manrope', sans-serif;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 25px;
        letter-spacing: -0.2px;
        color: $gray-900;
      }

      &:last-child {
        margin-bottom: 4px;
      }

      &:hover {
        background-color: $gray-200 !important;
      }
    }
  }

  //&.ng-select-searchable {
  //  .ng-dropdown-panel {
  //    .ng-dropdown-panel-items {
  //      .ng-option {
  //        &:first-child {
  //          margin-top: 0;
  //        }
  //      }
  //    }
  //  }
  //}
}

.ng-select-100 {
  .ng-dropdown-panel {
    width: 100%;
  }
}

.ng-select-text-nowrap {
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    white-space: inherit; // prevent ellipsis
  }
}

.ng-select-ellipsis {
  .ng-select-container {
    .ng-value-container {
      overflow: auto !important;
    }

    .ng-value-container .ng-value {
      .ng-value-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option .ng-option-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.ng-select-new {
  .ng-select-container {
    display: inline-flex !important;
    width: auto !important;
    min-height: 32px;
    max-height: 32px;
    height: 32px !important;
    border: none;
    border-radius: 6px !important;
    background-color: #ffffff;
    box-shadow: none !important;
    cursor: pointer !important;
    transition: all 0.3s ease;

    &:hover {
      background-color: $gray-50;
    }

    .ng-value-container {
      padding-left: 12px;
      padding-right: 12px;

      .ng-input {
        display: none !important;
      }

      .ng-placeholder {
        font-family: 'Manrope', sans-serif;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 20px;
        letter-spacing: -0.1px;
        color: $gray-600;
      }

      .ng-value {
        padding: 0;
        display: flex;
        align-items: center;

        .ng-value-label {
          font-family: 'Manrope', sans-serif;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 20px;
          letter-spacing: -0.1px;
          color: $gray-900;
        }

        .select-icon {
          svg {
            width: 16px;
            height: 16px;
            display: block;
          }
        }

        .arrow {
          display: block;
          margin-left: 12px;

          .arrow-icon {
            svg {
              width: 12px;
              height: 12px;
              display: block;
            }
          }
        }
      }
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      background-color: $gray-100 !important;
    }
  }
}

.ng-select-assign.ng-select-multiple {
  display: flex;

  .ng-select-container {
    display: inline-flex !important;
    width: auto !important;
    min-height: 32px;
    max-height: 32px;
    height: 32px !important;
    border: none;
    border-radius: 360px !important;
    background-color: $gray-200;
    box-shadow: none !important;
    cursor: pointer !important;
    transition: background 0.3s ease;

    &.ng-has-value {
      border-radius: 6px !important;
    }

    &:hover {
      background-color: $gray-250;
    }

    ui-avatar {
      margin-right: 8px;
      margin-left: -8px;
    }

    .ng-value-container {
      padding: 0 12px !important;

      .ng-value-label {
        padding: 0 !important;
      }

      .ng-input {
        display: none !important;
      }

      .ng-placeholder {
        font-family: 'Manrope', sans-serif;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 20px;
        letter-spacing: -0.1px;
        color: $gray-900;
      }

      .ng-value {
        font-size: inherit;
        margin-bottom: 0;
        background-color: transparent;
        border-radius: 0;
        margin-right: 0;
        padding: 0;
        display: flex;
        align-items: center;

        .ng-value-label {
          font-family: 'Manrope', sans-serif;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 20px;
          letter-spacing: -0.1px;
          color: $gray-900;
        }
      }
    }

    .ng-arrow-wrapper,
    .ng-clear-wrapper {
      width: 12px;
      height: 12px;
      padding: 0;
      display: block;
      margin-right: 12px;
      top: auto !important;
      transition: all 0.3s ease;
      transform-origin: center;
      will-change: transform;
      background-color: $gray-900;
      background-size: cover;
    }

    .ng-arrow-wrapper {
      -webkit-mask-image: url('/assets/icons/icons-arrow-short-down.svg');
      mask-image: url('/assets/icons/icons-arrow-short-down.svg');
      mask-size: 12px 12px;

      .ng-arrow {
        display: none;
      }
    }

    .ng-clear-wrapper {
      -webkit-mask-image: url('/assets/icons/icons-close-small.svg');
      mask-image: url('/assets/icons/icons-close-small.svg');
      mask-size: 12px 12px;

      &:hover {
        background-color: $red-500;
      }

      .ng-clear {
        display: none;
      }
    }

    &.ng-has-value {
      .ng-arrow-wrapper {
        display: none;
      }
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      background-color: $gray-300 !important;

      .ng-arrow-wrapper {
        .ng-arrow {
          transform: rotate(180deg);
        }
      }
    }
  }

  &.is-multiple-selected {
    .ng-select-container {
      .ng-arrow-wrapper,
      .ng-clear-wrapper {
        display: none;
      }

      ui-avatar {
        margin: 0 0 0 -6px;
        border-radius: 6px;
        border-left: 2px solid $gray-200;
        border-top: 2px solid $gray-200;
        border-bottom: 2px solid $gray-200;
      }

      .ng-value-container {
        padding: 0 4px 0 8px !important;

        .ng-value-label {
          display: none !important;
        }
      }
    }
  }

  .ng-dropdown-panel {
    left: auto;
    right: 0;

    .clear {
      display: none !important;
    }
  }
}

.ng-select-input {
  .ng-select-container {
    display: flex !important;
    width: 100% !important;
    min-height: 40px;
    max-height: 40px;
    height: 40px !important;
    padding: 8px 12px !important;
    border-radius: 8px !important;
    border: none !important;
    box-shadow: none !important;
    cursor: pointer !important;
    transition: all 0.3s ease;
    background: $gray-200 !important;

    .ng-value-container {
      padding-left: 0;
      padding-right: 0;

      .ng-input {
        display: none !important;
      }

      .ng-value {
        padding: 0;
        display: flex;
        align-items: center;
        width: 100%;

        .ng-value-label {
          font-family: 'Manrope', sans-serif;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 24px;
          letter-spacing: -0.1px;
          color: $gray-900;
          text-transform: none;
          transition: color 0.3s ease;
          width: 100%;
        }

        .select-icon {
          margin-right: 8px;

          svg {
            width: 16px;
            height: 16px;
            display: block;

            path {
              fill: $gray-900;
            }
          }
        }

        .arrow {
          display: block;
          margin-left: 12px;

          .arrow-icon {
            svg {
              width: 16px;
              height: 16px;
              display: block;

              path {
                fill: $gray-900;
              }
            }
          }
        }
      }
    }

    &:hover {
      .ng-value-container {
        .ng-value {
          .ng-value-label {
            color: $gray-900;
          }
        }
      }

      svg {
        path {
          fill: $gray-900;
        }
      }
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      svg {
        path {
          fill: $gray-900;
        }
      }
    }
  }

  .ng-dropdown-panel {
    width: 100%;
  }
}

.ng-select-input-small {
  .ng-select-container {
    display: flex;
    width: 100%;
    min-height: 32px;
    max-height: 32px;
    height: 32px;
    padding: 8px !important;
    border-radius: 8px !important;
    border: none !important;
    box-shadow: none !important;
    cursor: pointer !important;
    transition: background 0.3s ease;
    background: $gray-100 !important;

    .ng-value-container {
      padding-left: 0;
      padding-right: 0;

      .ng-input {
        display: none !important;
      }

      .ng-value {
        padding: 0;
        display: flex;
        align-items: center;
        width: 100%;

        .ng-value-label {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.1px;
          color: $gray-900;
          text-transform: none;
          transition: color 0.3s ease;
          width: 100%;
        }

        .arrow {
          margin-left: 0;

          .arrow-icon svg {
            width: 12px;
            height: 12px;
          }
        }

        ui-flag-icon {
          margin-right: 8px;

          .flag-icon {
            width: 22px;
            height: 16px;
          }
        }
      }
    }
  }

  .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 200px;
  }

  &.ng-select-opened {
    .ng-select-container {
      background: $gray-200 !important;
    }
  }

  .ng-dropdown-panel {
    min-width: 160px !important;
    width: auto;
    left: auto;
    right: 0;
    z-index: 9999;
  }
}

.ng-select-input-big {
  .ng-select-container {
    display: flex;
    width: 100%;
    min-height: 48px;
    max-height: 48px;
    height: 48px;
    //padding: 12px;
    padding: 12px 16px;
    border-radius: 12px !important;
    border: none !important;
    box-shadow: none !important;
    cursor: pointer !important;
    transition: background 0.3s ease;
    background: $gray-200 !important;

    .ng-value-container {
      padding-left: 0;
      padding-right: 0;

      .ng-input {
        display: none !important;
        top: 0 !important;
        bottom: 0;
        padding: 12px 16px !important;

        input {
          font-family: 'Manrope', sans-serif;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 24px;
          letter-spacing: -0.1px;
          color: $gray-900;
        }
      }

      .ng-value {
        padding: 0;
        display: flex;
        align-items: center;
        width: 100%;

        .ng-value-label {
          font-family: 'Manrope', sans-serif;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 24px;
          letter-spacing: -0.1px;
          color: $gray-900;
          text-transform: none;
          transition: color 0.3s ease;
          width: 100%;
        }

        ui-flag-icon {
          span {
            width: 28px;
            line-height: 20px;
            height: 20px;
            background-size: cover;
            background-position: center center;
            border-radius: 4px;
          }
        }

        .option-icon {
          margin-right: 12px;

          svg {
            width: 16px;
            height: 16px;
            display: block;
          }
        }

        .option-icon + span {
          line-height: 25px;
        }

        .select-icon {
          margin-right: 8px;

          svg {
            width: 16px;
            height: 16px;
            display: block;

            path {
              fill: $gray-900;
            }
          }
        }

        .arrow {
          display: none;
        }
      }
    }

    .ng-arrow-wrapper {
      width: 16px;
      height: 16px;
      padding: 0;
      display: block;
      top: auto !important;
      transition: transform 0.3s ease;
      transform-origin: center;
      will-change: transform;
      background-color: $gray-900;
      background-size: cover;
      -webkit-mask-image: url('/assets/icons/icons-arrow-down.svg');
      mask-image: url('/assets/icons/icons-arrow-down.svg');
      mask-size: 16px 16px;
      cursor: pointer;
      margin-left: 12px;

      .ng-arrow {
        display: none;
      }
    }

    &:not(.ng-select-disabled) {
      .ng-select-container:hover {
        .ng-value-container {
          .ng-value {
            .ng-value-label {
              color: $gray-900;
            }
          }
        }

        svg-icon:not(.option-icon) svg {
          path {
            fill: $gray-900;
          }
        }
      }
    }
  }

  &:not(.ng-select-disabled) {
    .ng-value-container {
      .ng-input {
        input {
          cursor: pointer !important;

          &:focus {
            cursor: initial !important;
          }
        }
      }
    }

    .ng-select-container:hover {
      .ng-value-container {
        .ng-value {
          .ng-value-label {
            color: $gray-900;
          }
        }
      }

      svg-icon:not(.option-icon) svg {
        path {
          fill: $gray-900;
        }
      }
    }
  }

  &.ng-invalid.ng-dirty {
    .ng-select-container {
      border: 2px solid $red-400 !important;
      background-color: $red-50 !important;
      padding: 8px 12px;

      .ng-value-container .ng-input {
        padding: 10px 14px !important;
      }
    }
  }

  &.ng-select-disabled {
    .ng-select-container {
      cursor: default !important;
      background-color: $gray-100 !important;

      .ng-arrow-wrapper {
        background-color: $gray-500 !important;
      }

      .ng-value-container {
        .ng-placeholder,
        .ng-value .ng-value-label:not([class*='type']) {
          color: $gray-500 !important;
        }
      }
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      background: $gray-250 !important;

      svg-icon:not(.option-icon) {
        path {
          fill: $gray-900;
        }
      }

      .ng-arrow-wrapper {
        transform: rotate(180deg);
      }
    }
  }

  &.ng-select-searchable {
    .ng-select-container {
      .ng-value-container {
        .ng-input {
          display: block !important;
        }
      }
    }
  }

  .ng-dropdown-panel {
    //min-width: 400px;

    .ng-dropdown-header + .ng-dropdown-panel-items {
      margin-top: -4px;
      margin-bottom: 4px;
    }

    .scrollable-content {
      height: calc(100% + 4px) !important;
      padding-bottom: 4px;
    }
  }
}

:host-context(.sh-form-readonly) {
  .ng-select-input-big {
    &.ng-select-disabled {
      .ng-select-container {
        background-color: #ffffff !important;

        .ng-arrow-wrapper {
          display: none !important;
        }

        .ng-value-container {
          .ng-placeholder,
          .ng-value .ng-value-label:not([class*='type']) {
            color: $gray-900 !important;
          }
        }
      }
    }
  }
}

.ng-select-input-big.ng-select-multiple:not(.ng-select-tags) {
  .ng-select-container {
    height: auto;
    max-height: 96px;
    transition: background 0.3s ease;

    .ng-value-container {
      padding: 0;

      .ng-placeholder {
        font-family: 'Manrope', sans-serif;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $gray-900;
      }

      .ng-input {
        display: none;
      }

      .ng-value {
        max-width: 215px;
        width: auto;
        overflow: hidden;
        display: flex;
        height: 32px;
        margin: 0 8px 8px 0;
        border-radius: 8px;
        background: #ffffff;

        .ng-value-clear {
          display: flex;
          align-items: center;
          border-left: 1px solid $gray-200 !important;
          padding: 0 8px;
          height: 100%;
          width: 29px;
          transition: all 0.3s ease;

          svg-icon {
            svg {
              display: block;
              width: 12px;
              height: 12px;

              path {
                fill: $gray-700;
                transition: all 0.3s ease;
              }
            }
          }

          &:hover {
            svg-icon {
              svg {
                path {
                  fill: $gray-900;
                }
              }
            }
          }
        }

        .ng-value-label {
          order: 0;
          font-family: 'Manrope', sans-serif;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 25px;
          letter-spacing: -0.2px;
          width: auto;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 0 8px;

          &:before {
            vertical-align: 1px;
          }
        }
      }
    }

    .ng-arrow-wrapper {
      width: 16px;
      height: 16px;
      padding: 0;
      display: block;
      top: auto !important;
      transition: transform 0.3s ease;
      transform-origin: center;
      will-change: transform;
      background-color: $gray-900;
      background-size: cover;
      -webkit-mask-image: url('/assets/icons/icons-arrow-down.svg');
      mask-image: url('/assets/icons/icons-arrow-down.svg');
      mask-size: 16px 16px;
      cursor: pointer;

      .ng-arrow {
        display: none;
      }
    }

    &.ng-has-value {
      padding: 8px 12px 0 8px;

      .ng-arrow-wrapper {
        margin-bottom: 8px;
      }
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      .ng-arrow-wrapper {
        transform: rotate(180deg);
      }
    }
  }

  .ng-dropdown-panel {
    width: 100%;
  }
}

.ng-select-pill {
  .ng-select-container {
    display: inline-flex !important;
    width: auto !important;
    min-height: 32px;
    max-height: 32px;
    height: 32px !important;
    padding: 8px 11px !important;
    border-radius: 16px !important;
    border: solid 1px $gray-350 !important;
    box-shadow: none !important;
    cursor: pointer !important;
    transition: all 0.3s ease;
    background: transparent !important;

    .ng-value-container {
      padding-left: 0;
      padding-right: 0;

      .ng-input {
        display: none !important;
      }

      .ng-placeholder {
        font-family: 'Manrope', sans-serif;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 16px;
        letter-spacing: -0.1px;
        color: $gray-800;
        text-transform: none;
        transition: color 0.3s ease;
      }

      .ng-value {
        padding: 0;
        display: flex;
        align-items: center;

        .option-icon {
          margin-right: 8px;

          svg {
            width: 16px;
            height: 16px;
            display: block;
          }
        }

        .ng-value-label {
          font-family: 'Manrope', sans-serif;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 16px;
          letter-spacing: -0.1px;
          color: $gray-800;
          text-transform: none;
          transition: color 0.3s ease;
        }

        .select-icon {
          svg {
            width: 16px;
            height: 16px;
            display: block;
            margin-right: 8px;

            path {
              fill: $gray-800;
            }
          }
        }

        .arrow {
          display: block;
          margin-left: 12px;

          .arrow-icon {
            svg {
              width: 12px;
              height: 12px;
              display: block;

              path {
                fill: $gray-800;
              }
            }
          }
        }
      }
    }

    .ng-spinner-loader {
      margin-left: 8px;
    }

    &:hover {
      border: solid 1px $gray-400 !important;

      .ng-value-container {
        .ng-value {
          .ng-value-label {
            color: $gray-900;
          }
        }
      }

      svg {
        path {
          fill: $gray-900 !important;
        }
      }
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      border: solid 1px $gray-300 !important;
      background-color: $gray-300 !important;
      z-index: 1 !important;

      .arrow {
        .arrow-icon {
          transform: rotate(180deg);
        }
      }

      svg {
        path {
          fill: $gray-900;
        }
      }
    }
  }
}

.ng-select-secondary-small {
  .ng-select-container {
    display: flex !important;
    width: 100% !important;
    min-height: 32px;
    max-height: 32px;
    height: 32px !important;
    padding: 8px !important;
    border-radius: 8px !important;
    border: none !important;
    box-shadow: none !important;
    cursor: pointer !important;
    transition: all 0.3s ease;
    background: $gray-200 !important;

    .ng-value-container {
      padding-left: 0;
      padding-right: 0;

      .ng-input {
        display: none !important;
      }

      .ng-value {
        padding: 0;
        display: flex;
        align-items: center;
        width: 100%;

        .option-icon {
          svg {
            width: 16px;
            height: 16px;
            display: block;
          }
        }

        .ng-value-label {
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.2px;
        }

        .select-icon {
          margin-right: 8px;

          svg {
            width: 16px;
            height: 16px;
            display: block;

            path {
              fill: $gray-900;
            }
          }
        }

        .arrow {
          display: block;
          margin-left: 12px;

          .arrow-icon {
            svg {
              width: 12px;
              height: 12px;
              display: block;

              path {
                fill: $gray-900;
              }
            }
          }
        }
      }
    }

    &:hover {
      background: $gray-250 !important;

      .ng-value-container {

        .ng-value {
          .ng-value-label {
            color: $gray-900;
          }
        }
      }

      svg-icon:not(.option-icon) svg {
        path {
          fill: $gray-900;
        }
      }
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      background: $gray-300 !important;

      svg-icon:not(.option-icon) svg {
        path {
          fill: $gray-900;
        }
      }
    }
  }

  .ng-dropdown-panel {
    min-width: 80px !important;
    width: auto;
    left: auto;
    right: 0;
    z-index: 9999;
  }
}

.ng-select-pill-secondary {
  .ng-select-container {
    display: flex !important;
    width: 100% !important;
    min-height: 40px;
    max-height: 40px;
    height: 40px !important;
    padding: 12px !important;
    border-radius: 360px !important;
    border: none !important;
    box-shadow: none !important;
    cursor: pointer !important;
    transition: all 0.3s ease;
    background: $gray-200 !important;

    .ng-value-container {
      padding-left: 0;
      padding-right: 0;

      .ng-input {
        display: none !important;
      }

      .ng-value {
        padding: 0;
        display: flex;
        align-items: center;
        width: 100%;

        .option-icon {
          svg {
            width: 16px;
            height: 16px;
            display: block;
          }
        }

        .ng-value-label {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: -0.2px;
          color: $gray-900;
          text-transform: none;
          transition: color 0.3s ease;
          width: 100%;
        }

        .select-icon {
          margin-right: 8px;

          svg {
            width: 16px;
            height: 16px;
            display: block;

            path {
              fill: $gray-900;
            }
          }
        }

        .arrow {
          display: block;
          margin-left: 16px;

          .arrow-icon {
            svg {
              width: 16px;
              height: 16px;
              display: block;

              path {
                fill: $gray-900;
              }
            }
          }
        }
      }
    }

    &:hover {
      background: $gray-250 !important;

      .ng-value-container {

        .ng-value {
          .ng-value-label {
            color: $gray-900;
          }
        }
      }

      svg-icon:not(.option-icon) svg {
        path {
          fill: $gray-900;
        }
      }
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      background: $gray-300 !important;

      svg-icon:not(.option-icon) svg {
        path {
          fill: $gray-900;
        }
      }
    }
  }

  &-small {
    .ng-select-container {
      min-height: 32px !important;
      max-height: 32px !important;
      height: 32px !important;
      padding: 8px 12px !important;

      .ng-value-container {
        .ng-value {
          .arrow {
            margin-left: 12px;

            .arrow-icon {
              svg {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
      }
    }
  }
}

.ng-select-tags.ng-select-multiple {
  .ng-select-container {
    transition: background 0.3s ease;
    max-height: 100%;
    height: auto;
    padding: 8px 12px 8px 6px !important;

    .ng-value-container {
      padding: 0;
      gap: 6px;

      .ng-value {
        max-width: 215px;
        width: auto;
        overflow: hidden;
        display: flex;
        height: 32px;
        border-radius: 16px;
        border: solid 1px $gray-300;
        background: #ffffff;
        padding-left: 10px;
        margin: 0;

        .ng-value-clear {
          display: flex;
          align-items: center;
          border-left: 1px solid $gray-200 !important;
          padding: 0 12px 0 7px;
          height: 100%;
          width: 30px;
          transition: all 0.3s ease;

          svg-icon {
            svg {
              display: block;
              width: 12px;
              height: 12px;

              path {
                fill: $gray-700;
                transition: all 0.3s ease;
              }
            }
          }

          &:hover {
            svg-icon {
              svg {
                path {
                  fill: $gray-900;
                }
              }
            }
          }
        }

        .ng-value-label {
          order: 0;
          font-family: 'Manrope', sans-serif;
          width: auto;
          padding: 0 8px 0 0;

          font-size: 11px;
          font-weight: 700;
          font-stretch: normal;
          font-style: normal;
          line-height: 16px;
          letter-spacing: normal;
          color: $gray-900;
          text-transform: uppercase;
        }

        .tags-icon {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
      }

      .ng-input {
        font-family: 'Manrope', sans-serif;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $gray-900;
        display: block !important;
        padding: 0 !important;
      }
    }

    .ng-arrow-wrapper {
      width: 16px;
      height: 16px;
      padding: 0;
      display: block;
      top: auto !important;
      transition: transform 0.3s ease;
      transform-origin: center;
      will-change: transform;
      background-color: $gray-900;
      background-size: cover;
      -webkit-mask-image: url('/assets/icons/icons-arrow-down.svg');
      mask-image: url('/assets/icons/icons-arrow-down.svg');
      mask-size: 16px 16px;
      cursor: pointer;

      .ng-arrow {
        display: none;
      }
    }

    &.ng-has-value {
      padding: 0 12px 0 8px;
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      .ng-arrow-wrapper {
        transform: rotate(180deg);
      }
    }
  }

  .ng-dropdown-panel {
    width: 100%;

    .ng-dropdown-panel-items .ng-option {
      &:first-child {
        margin-top: 4px;
      }

      .tags-icon {
        width: 16px;
        height: 16px;
        margin-right: 12px;
      }

      span {
        font-family: 'Manrope', sans-serif;
        font-size: 16px;
        font-weight: 500 !important;
        font-stretch: normal;
        font-style: normal;
        line-height: 25px;
        letter-spacing: -0.2px;
        color: $gray-900;
      }
    }
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(-3px) scale(0.98);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.ng-select-focused {
  .ng-input {
    cursor: text !important;

    input {
      cursor: initial !important;
    }
  }
}

.ng-select-template {
  .ng-dropdown-header {
    padding: 4px;
    border: none;
    position: relative;
    z-index: 9999;
    background: #ffffff;

    .search-input {
      border-radius: 6px;
      box-shadow: 0 1px 0 0 $gray-100;
      border: solid 1px $gray-250;
      background-color: $white;
      font-family: 'Manrope', sans-serif;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 38px;
      letter-spacing: -0.2px;
      color: $gray-900;
      width: 100%;
      padding: 0 12px;
      height: 38px;
      appearance: none;
      outline: none;

      &:focus {
        outline: none;
      }

      @include placeholder {
        color: $gray-600;
      }
    }
  }

  .ng-dropdown-panel {

    .ng-dropdown-panel-items {
      max-height: 185px;
    }

    .ng-dropdown-panel-items {
      .ng-option {
        margin: 0 4px 2px;
        padding: 8px 12px;
        border-radius: 8px !important;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        height: auto;
        line-height: 25px;
        position: relative;

        .ng-option-column {
          display: flex;
          flex-direction: column;
        }

        .ng-option-template {
          margin-top: 8px;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          white-space: pre-line;
        }

        .ng-option-label {
          font-family: 'Manrope', sans-serif;
          font-size: 16px;
          font-weight: 500 !important;
          font-stretch: normal;
          font-style: normal;
          line-height: 25px;
          letter-spacing: -0.2px;
          color: $gray-900;
        }
      }
    }
  }
}
